import type { InputProps } from './InputInnerLabel.props'
import { forwardRef, ForwardedRef } from 'react'
import styles from './InputInnerLabel.module.css'
import cn from 'classnames'
import styled from 'styled-components'

const InputWrapper = styled.div`
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-transition-delay: 9999999s;
    transition-delay: 9999999s;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }

  .Mui-focused .MuiOutlinedInput-notchedOutline {
    /* border-color: #d3d4d3 !important; */
    border-color: orange !important; // #EDF1F6
  }
`

// eslint-disable-next-line react/display-name
export const InputInnerLabel = forwardRef(
  (
    {
      onChange,
      className,
      label,
      type,
      // error,
      ...props
    }: InputProps,
    ref: ForwardedRef<HTMLInputElement>,
  ): JSX.Element => {
    return (
      <InputWrapper>
        <div className={cn(className, styles.inputWrapper)}>
          <input
            // onChange={e => onChange(e)}
            type={type}
            onChange={onChange}
            className={cn(styles.input, {
              // [styles.error]: error
            })}
            ref={ref}
            {...props}
          />
          <div className={styles.label}>{label}</div>
          {/* {error && (
						<span role="alert" className={styles.errorMessage}>
							{error.message}
						</span>
					)} */}
        </div>
      </InputWrapper>
    )
  },
)
