import type { IInputTopLabel } from './Input.props'
import TextField from '@mui/material/TextField'
import styled from 'styled-components'

const TextFieldStyled = styled(TextField)``
export const InputTopLabel = ({
  name,
  label,
  value,
  onChange,
  autoFocus = false,
  type,
}: IInputTopLabel) => (
  <TextFieldStyled
    id='outlined-basic'
    type={type}
    name={name}
    label={label}
    value={value}
    onChange={onChange}
    variant='outlined'
    autoFocus={autoFocus}
  />
)
