import { apiSlice } from 'redux/api/auth/apiSlice'
import { APIEndpoints } from 'types/api'

type ITenantIDArgs = {
  id: number
  last_name: string
  first_name: string
  middle_name: string
  phone: string
}

type IGetTenantsArgs = {
  query: string
  flats: any
  residential_complex: number
  some: any
}

type ICreateTenantArgs = {
  phone: string
  first_name: string
  last_name: string
  middle_name: string
}

interface IEditTenantArgs extends ICreateTenantArgs {
  id: number
}

export const tenantsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // getTenants: builder.query<IGetTenantsArgs, any>({
    getChannelsHistory: builder.query<any, any>({
      query: () => {
        return {
          url: `${APIEndpoints.CHANNELS}channel_history/`,
        }
      },
      keepUnusedDataFor: 0,
    }),
    getChannels: builder.query<any, any>({
      query: () => {
        return {
          url: `${APIEndpoints.CHANNELS}`,
          // params: {
          //   query,
          //   flats,
          //   residential_complex,
          // },
        }
      },
      keepUnusedDataFor: 0,
    }),

    getChannelsById: builder.query<any, any>({
      query: ({ id }: any) => {
        return {
          url: `${APIEndpoints.CHANNELS}${id}/`,
        }
      },
      keepUnusedDataFor: 0,
    }),

    editChannel: builder.mutation<any, any>({
      query: ({ id, residential_complex, message }: any) => ({
        url: `${APIEndpoints.CHANNELS}${id}/`,
        method: 'PUT',
        body: {
          message,
          residential_complex,
          // city: 0,
          // building: 0,
          // flat: 0,
        },
      }),
    }),
    // // getTenantById: builder.query<ITenantIDArgs, any>({
    // getTenantById: builder.query<any, any>({
    //   query: ({ id }: ITenantIDArgs) => {
    //     return {
    //       url: `${APIEndpoints.TENANTS}${id}`,
    //     }
    //   },
    // }),
  }),
})

export const {
  useGetChannelsHistoryQuery,
  useGetChannelsQuery,
  useEditChannelMutation,
  useGetChannelsByIdQuery,
} = tenantsApiSlice
