/* eslint-disable autofix/no-unused-vars */
/* eslint-disable  @typescript-eslint/no-explicit-any*/
import * as React from 'react'
import { Box, Pagination, Stack, TablePagination } from '@mui/material'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import { visuallyHidden } from '@mui/utils'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'

const TableCellStyled = styled(TableCell)`
  &&& {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #5e5e5e;
    background: #f8f8f8;

    text-align: left;
    border-bottom: 1px solid #edf1f6;
    span {
      &::first-letter {
        text-transform: capitalize;
      }
    }
    &::first-letter {
      text-transform: capitalize;
    }
    &:last-child {
      border-bottom: none;
    }
  }
`

const TableCellBodyStyled = styled(TableCell)`
  &&& {
    border-bottom: 1px solid #edf1f6;
    text-align: left;
  }
`

interface Data {
  constructions: string
  levels: number
  apartments: number
  tenants: number
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

type Order = 'asc' | 'desc'

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort<T>(array: any, comparator: (a: T, b: T) => number) {
  // function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el: any, index: any) => [el, index] as [T, number])
  // const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a: any, b: any) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) {
      return order
    }
    return a[1] - b[1]
  })
  return stabilizedThis.map((el: any) => el[0])
}

interface EnhancedTableProps {
  numSelected: number
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void
  order: Order
  orderBy: string
  rowCount: number
  headCells: any
}

const TableHeadStyled = styled(TableHead)`
  padding: 4px;
`

interface ITableContainerStyled {
  islinked?: boolean
}

const TableContainerStyled = styled(TableContainer)<ITableContainerStyled>`
  border: 1px solid #edf1f6;
  border-radius: 8px;
  &:last-child {
    border-bottom: none;
  }

  .MuiTableCell-root {
    padding-left: 16px;
    padding-right: 16px;
  }

  .MuiTableHead {
    tr {
      cursor: pointer;
      background: red;
    }
    &:hover {
      cursor: none;
      background: red;
    }
  }

  .MuiTableRow-root {
    border-bottom: 1px solid #edf1f6;

    /* &:last-child {
      border-bottom: none;
    } */

    td a {
      opacity: ${({ islinked }) => (islinked ? 0 : 1)};
    }
    &:hover {
      cursor: pointer;
      transition: all ease-in-out 0.3s;
      background: #f7f8fc;
      td a {
        opacity: 1;
      }
    }
  }

  .MuiTableRow-head {
    height: 15px;
    box-sizing: border-box;
    border-bottom: 1px solid #edf1f6;

    background: transparent;
    &:hover {
      cursor: default;
      transition: all ease-in-out 0.3s;
      background: transparent;
    }
  }
`

function EnhancedTableHead(props: EnhancedTableProps) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props
  const createSortHandler = (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property)
  }

  return (
    <TableHeadStyled>
      <TableRow>
        {props.headCells.map((headCell: any, index: number) => (
          <TableCellStyled
            key={headCell.id}
            // sortDirection={false}
            align={headCell.numeric ? 'right' : 'left'}
            padding={index == 0 ? 'none' : headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            color={'#9898A6'}
          >
            {headCell.sortable ? (
              <TableSortLabel
                hideSortIcon={headCell.hideSortIcon}
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
                style={{ width: 'max-content' }}
              >
                <span>{headCell.label}</span>
                {orderBy === headCell.id ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            ) : (
              <span>{headCell.label}</span>
            )}
          </TableCellStyled>
        ))}
      </TableRow>
    </TableHeadStyled>
  )
}

export const TableSort = ({
  rowsData,
  headCells,
  numOfItemsPerPage = 10,
}: {
  rowsData: any
  headCells: any
  numOfItemsPerPage?: number
}) => {
  const [islinked, setislinked] = React.useState<boolean>(false)
  const navigate = useNavigate()
  const [rows, setRows] = React.useState<any[]>([])

  React.useEffect(() => {
    setRows([])

    setislinked(rowsData?.length > 0 && Object.keys(rowsData[0]).includes('link'))
    setRows([...rowsData.map((obj: any) => Object.values(obj))])
  }, [rowsData])

  const [order, setOrder] = React.useState<Order>('asc')
  const [orderBy, setOrderBy] = React.useState<keyof Data>('levels')
  const [selected, setSelected] = React.useState<readonly any[]>([])
  // const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(numOfItemsPerPage)

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = rows.map((row) => Object.values(row)[0])
      // const newSelected = rows.map((n) => n.constructions);
      setSelected(newSelected)
      return
    }
    setSelected([])
  }

  const handleClick = (event: React.MouseEvent<unknown>, name: any) => {
    const selectedIndex = selected.indexOf(name)
    let newSelected: readonly string[] = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      )
    }

    setSelected(newSelected)
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const isSelected = (name: any) => selected.indexOf(name) !== -1

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0

  const numOfPages =
    rowsData?.length > numOfItemsPerPage ? Math.ceil(rowsData?.length / numOfItemsPerPage) : 0

  return (
    <Box sx={{ width: '100%' }}>
      {/* <Paper sx={{ width: '100%', mb: 2 }}> */}
      <TableContainerStyled islinked={islinked}>
        <Table sx={{ minWidth: 750 }} aria-labelledby='tableTitle' size={'medium'}>
          <EnhancedTableHead
            headCells={headCells}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
          />
          <TableBody>
            {stableSort(rows, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row: any, index: number) => {
                const isItemSelected = isSelected(row.constructions)
                const labelId = `enhanced-table-checkbox-${index}`
                return (
                  <TableRow
                    // hover
                    onClick={(event: React.MouseEvent<unknown>) => {
                      console.log('PEOPLE', 10 * page + (index + 1) - 1)

                      handleClick(event, Object.values(row)[0])
                      islinked && navigate(`${rowsData[10 * page + (index + 1) - 1].link}`)
                      // islinked && navigate(`${rowsData[index].link}`)
                    }}
                    role='checkbox'
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={`${Object.values(row)[index]}+${index}`} // KEY IS IMMPORTANT, OTHERWISE TABLE WILL BE MAD
                    selected={isItemSelected}
                  >
                    {Object.values(row).map((item: any, index) => {
                      return islinked && index == row.length - 1 ? null : index === 0 ? (
                        // return islinked && index == row.length - 1 ? null : index === 0 ? (
                        <TableCellBodyStyled
                          component='th'
                          id={labelId}
                          scope='row'
                          padding='none'
                          key={`${Object.values(row)[index]}+${index}`}
                        >
                          {item}
                        </TableCellBodyStyled>
                      ) : (
                        <TableCellBodyStyled
                          align='right'
                          key={`${Object.values(row)[index]}+${index}`}
                        >
                          {item}
                        </TableCellBodyStyled>
                      )
                    })}
                  </TableRow>
                )
              })}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: 53 * emptyRows,
                }}
              >
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainerStyled>

      {numOfPages > 0 && (
        <Stack marginTop={3}>
          <Pagination
            count={numOfPages}
            color='primary'
            onChange={
              (e, page) => setPage(--page) // отсчёт страниц с нуля
            }
          />
        </Stack>
      )}

      {/* <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component='div'
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      /> */}
    </Box>
  )
}
