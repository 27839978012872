import { Button } from 'components'
import styled from 'styled-components'
import type { HashtagContainerProps, HashtagProps } from './Hashtag.props'
import { ReactComponent as CrossHash } from './images/cross_hash.svg'

export const HashtagsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const HashtagContainer = styled.div<HashtagContainerProps>`
  padding: 5px 15px;
  text-align: center;
  margin-right: 18px;
  margin-top: 15px;
  background-color: ${({ bg }) => bg};
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #4060ff;

  height: 18px;
  width: 18px;
`

export const Hashtag = ({ title, onDeleteHandler, color }: HashtagProps) => {
  return (
    <HashtagContainer bg={color}>
      {title}
      <Button appearance='container' onClick={() => onDeleteHandler()}>
        <CrossHash />
      </Button>
    </HashtagContainer>
  )
}
