import { FC, Suspense } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { LoaderScreenWrapper, Loader } from 'components'
import Public from 'routes/Public'
import RequireAuth from 'routes/RequireAuth'
import { protectedRoutes, publicRoutes } from './routes'
import { IRoute } from './types'

export const RoutesProvider: FC = () => (
  <BrowserRouter>
    <Suspense
      fallback={
        <LoaderScreenWrapper>
          <Loader />
        </LoaderScreenWrapper>
      }
    >
      <Routes>
        <Route element={<Public />} />
        {publicRoutes.map(({ path, element }: IRoute) => (
          <Route path={path} element={element} key={path} />
        ))}
        {/* protected routes */}
        <Route element={<RequireAuth />}>
          {protectedRoutes.map(({ path, element }: IRoute) => (
            <Route path={path} element={element} key={path} />
          ))}
        </Route>
      </Routes>
    </Suspense>
  </BrowserRouter>
)
