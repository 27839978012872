import { createSlice, PayloadAction, current } from '@reduxjs/toolkit'
import { ChatStatus } from './chatsApiSlice'

interface IState {
  currentChatMessage: any
  chatRefFlatData: any
  chatsData: any
  chatsDataSearch: any
  searchWordChatList: any
  is_active_chats: ChatStatus
  totalMsgNum: any
  countMsg: number
  isMsgListReset: boolean
  triggerPage: any
  totalPagesOnSockets: any
  // currentChatMsgPaginator: any
}

const initialState: IState = {
  totalMsgNum: 0,
  currentChatMessage: [],
  chatRefFlatData: {},
  searchWordChatList: '',
  is_active_chats: ChatStatus.OPEN,
  chatsData: [],
  chatsDataSearch: [],
  countMsg: 0,
  isMsgListReset: true,
  triggerPage: undefined,
  totalPagesOnSockets: 5,
  // currentChatMsgPaginator: {
  //   page: 1,
  //   count: null,
  //   lastPage: null,
  // },
}

const chatsReducer = createSlice({
  name: 'chats',
  initialState,
  reducers: {
    // setCurrentChatMsgPaginator(state, action: PayloadAction<any>) {
    //   state.currentChatMessage = action.payload
    // },
    setResetMsgList(state) {
      state.currentChatMessage = []
      state.countMsg = 0
      state.isMsgListReset = true
    },
    setCurrentMsgListInStore(state, action: PayloadAction<any>) {
      if (!action.payload.results) {
        state.isMsgListReset = true
        state.currentChatMessage = []
      } else {
        state.isMsgListReset = false
        state.currentChatMessage = [...current(state.currentChatMessage), ...action.payload.results]
        state.countMsg = action.payload.count
      }
    },

    setAddIncomingMsgInChat: (state, action) => {
      if (
        Number(action.payload?.chat_id) === Number(state.chatRefFlatData?.chatsDataFSM?.reference)
      ) {
        const currentMsgList = current(state.currentChatMessage)
        const updatedMsgList = [...currentMsgList, action.payload]
        state.currentChatMessage = updatedMsgList
      }
    },

    setChatRefFlatData: (state, action) => {
      console.log('UPD: chatref', action.payload) // reference
      state.isMsgListReset = false
      state.chatRefFlatData = action.payload
    },
    // 4444
    setUpdIsActiveChatsListData: (state, action) => {
      // console.log('chatStatusNew- chatStatusNew====', action.payload, current(state.chatsData))
      console.log(
        'chatStatusNew-MEEEE',
        action.payload,
        // action.payload,
        // current(state.chatsData),
      )
      state.chatsData = state.chatsData.map((obj: any) => {
        if (obj.id === action.payload.id) {
          return {
            ...obj,
            chatsDataFSM: {
              ...obj.chatsDataFSM,
              // is_active: false,
              is_active: action.payload.is_active,
              responsible: null,
              // responsible: action.payload.is_active === true ? action.payload.me : null,
              status: action.payload.is_active === true ? ChatStatus.OPEN : ChatStatus.CLOSED,
            },
          }
        } else {
          return obj
        }
      })
      // console.log('chatStatusNew 26*', current(state.chatsData))
      // state.chatsData.chatsDataFSM.responsible = null
      // state.chatRefFlatData.chatsDataFSM.responsible = null
      // state.chatRefFlatData = {}
    },
    // setUpdIsActiveChatsListData: (state, action) => {
    //   console.log('chatStatusNew====', action.payload, current(state.chatsData))
    //   state.chatsData = state.chatsData.map((obj: any) => {
    //     if (obj.id === action.payload) {
    //       return {
    //         ...obj,
    //         chatsDataFSM: {
    //           ...obj.chatsDataFSM,
    //           // is_active: false,
    //           responsible: null,
    //           status: ChatStatus.CLOSED,
    //         },
    //       }
    //     } else {
    //       return obj
    //     }
    //   })
    //   state.chatRefFlatData.chatsDataFSM.responsible = null
    // },
    setChatsListData: (state, action) => {
      // if (state.chatsData.length > 0) return // TODO ! onChatsData re-renders and sets totalMsgNUm on every msg

      if (state.chatsData?.length <= 0) {
        console.log('TE_SAM_chats.setChatsListData', action.payload?.chats)
        state.chatsData = action.payload?.chats?.filter(
          (chat: any) => chat?.chatsDataFSM?.chat_type !== 'channel',
        )

        state.totalMsgNum = action.payload?.total_unseen_messages_count
      } else {
        const uniqueChats = [...current(state.chatsData), ...action.payload.chats].reduce(
          (acc, current) => {
            if (!acc.some((item: any) => item.id === current.id)) {
              acc.push(current)
            }
            return acc
          },
          [],
        )

        state.chatsData = [...uniqueChats]
        state.totalMsgNum = action.payload?.total_unseen_messages_count
      }
    },
    setChatsListSearchData: (state, action) => {
      // if (state.chatsData.length > 0) return // TODO ! onChatsData re-renders and sets totalMsgNUm on every msg

      state.chatsDataSearch = action.payload?.chats
    },
    setSearchWordChatList: (state, action) => {
      state.searchWordChatList = action.payload
    },
    setIsActiveChatsStore: (state, action) => {
      console.log('setIsActiveChatsStore ACTION_PAYLOAD: ', action.payload) // Новое сообщение
      state.is_active_chats = action.payload
    },

    setUnseenMessages: (state, action) => {
      console.log('ACTION_PAYLOAD: ', action.payload) // Новое сообщение
    },

    updResponsibleForChat(state: any, action: PayloadAction<any>) {
      const { chat_id, responsible, status, is_active, reference } = action.payload

      console.log('ACTION_PAYLOAD_responsible: ', responsible) // Новое сообщение
      state.chatRefFlatData.chatsDataFSM.responsible = responsible

      state.chatsData = state.chatsData.map((obj: any) => {
        if (obj.id == reference) {
          console.log('chatStatusNew obj', responsible, reference, obj.id)
          return {
            ...obj,
            chatsDataFSM: {
              ...obj.chatsDataFSM,
              status,
              is_active: false,
              responsible,
            },
          }
        } else {
          return obj
        }
      })

      // const index = state.chatsData.findIndex((obj: any) => Number(obj.id) === Number(chat_id))
      // console.log(
      //   '_LOG___action.payload998',
      //   index,
      //   // state.chatsData[index].chatsDataFSM,
      //   state.chatsData[index].unseen_messages_count,
      // )

      // state.chatsData[index].chatsDataFSM.responsible = responsible
    },
    setMsgSeen(state: any, action: PayloadAction<any>) {
      const chat_id = action.payload

      const index = state.chatsData.findIndex((obj: any) => obj.id === chat_id)

      state.totalMsgNum = state.totalMsgNum - state.chatsData[index].unseen_messages_count
      state.chatsData[index].unseen_messages_count = 0
    },

    updChatsPreviewData(state: any, action: PayloadAction<any>) {
      const { chat_id } = action.payload

      // Chats Objects
      const arrWithoutUpdChat = state.chatsData?.filter((chat: any) => chat.id != chat_id) || []
      const chatToUpdate = state.chatsData?.find((chat: any) => chat.id == chat_id)

      if (!chatToUpdate) {
        console.log(`Chat with id ${chat_id} not found`)
        return // Exit the reducer if the chat is not found
      }

      const updMessages = { ...current(chatToUpdate), last_message: action.payload }

      // спредим объект
      const objForUpdMsg = {
        ...updMessages,
        unseen_messages_count:
          Number(chat_id) === Number(state.chatRefFlatData?.chatsDataFSM?.reference)
            ? 0
            : (chatToUpdate.unseen_messages_count || 0) + 1,
      }

      const updChats = [objForUpdMsg, ...arrWithoutUpdChat]

      state.chatsData = updChats

      if (Number(chat_id) !== Number(state.chatRefFlatData?.chatsDataFSM?.reference)) {
        state.totalMsgNum = (state.totalMsgNum || 0) + 1
      }
    },

    setTriggerPage(state: any, action: PayloadAction<any>) {
      state.triggerPage = action.payload
    },
    setTotalPagesOnSockets(state: any, action: PayloadAction<any>) {
      state.totalPagesOnSockets = action.payload + 1
    },
  },
})

export const {
  setResetMsgList,
  setCurrentMsgListInStore,
  setAddIncomingMsgInChat,
  setChatRefFlatData,
  // setChatsData,
  setMsgSeen,
  updChatsPreviewData,
  setUnseenMessages,
  setChatsListData,
  setChatsListSearchData,
  setSearchWordChatList,
  updResponsibleForChat,
  setIsActiveChatsStore,
  setTriggerPage,
  setTotalPagesOnSockets,
  setUpdIsActiveChatsListData,
} = chatsReducer.actions
export default chatsReducer.reducer
