export enum AddTenantOption {
  FROM_DB = 'Выбрать из жильцов',
  ADD_NEW_USER = 'Указать клиета для связи',
}

export enum Status {
  NEW = 'NEW',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  CLOSED = 'CLOSED',
}

export enum Priority {
  LOW = 'LOW',
  REGULAR = 'REGULAR',
  URGENT = 'URGENT',
  EMERGENCY = 'EMERGENCY',
}

export const PriorityColor: any = {
  LOW: '#626F84',
  EMERGENCY: '#FF3B30',
  REGULAR: '#2930FB',
  URGENT: '#FF8C19',
}

export const PriorityName: any = {
  LOW: 'Низкая',
  REGULAR: 'Обычная',
  URGENT: 'Аварийная',
  EMERGENCY: 'Срочная',
}

export const TypeName: any = {
  WARRANTY: 'Гарантийный',
  MANAGEMENT_COMPANY: 'УК',
}

export const StatusColor: any = {
  NEW: '#2930FB',
  IN_PROGRESS: '#FF8C19',
  COMPLETED: '#34C759',
  CLOSED: '#626F84',
  EMERGENCY: '#FF3B30',
}

export const StatusName: any = {
  NEW: 'Новая',
  IN_PROGRESS: 'В работе',
  COMPLETED: 'Выполнена',
  CLOSED: 'Закрыта',
}
