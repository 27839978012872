import React, { useState } from 'react'
import styles from './Switcher.module.css'
import cn from 'classnames'
import { SwitcherProps } from './Switcher.props'

export const Switcher = ({ state, onChangeHandler, className }: SwitcherProps): JSX.Element => {
  const [localState, setLocalState] = useState<boolean>(false)

  useState(() => {
    setLocalState(state)
  })

  return (
    <label className={cn(className, styles.switch)}>
      <input
        type='checkbox'
        defaultChecked={state}
        // TODO switcher change state on complex page, to make it reusable
        onChange={() => onChangeHandler(localState, setLocalState)}
      />
      <span className={cn(styles.slider, styles.round)}></span>
    </label>
  )
}
