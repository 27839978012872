import { FC, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Button, Dropdown, Logo } from 'components'
import { SideMenuList } from './SideMenuList/SideMenuList'
import styles from './Layout.module.css'
import { ReactComponent as AvatarSvg } from 'layout/img/avatar.svg'
import { sidebarMenu } from './sideMenu'
import { setProfileData, useGetProfileQuery } from 'redux/features/profile/profileApiSlice'
import type { LayoutProps } from './Layout.props'
import type { IProfile } from 'redux/features/profile/profileApiSlice.props'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'
import { setIsOpenedNav } from 'redux/features/sidebar/sidebarApiSlice'
import { ReactComponent as ArrowSvg } from 'assets/img/sidebar_arrow.svg'
import { SocketProvider } from 'providers/socketProvider/SocketProvider'

const DropdownMenu = ({
  profileData: { email, first_name, last_name, middle_name },
}: {
  profileData: IProfile
}) => (
  <Dropdown
    btnTitle={
      <div className={styles.dropbtn_title}>
        <AvatarSvg /> {/* {img ? <Avatar size='32px' img={'img_url'} /> : <AvatarSvg />}   */}
        <div className={styles.user}>{`${first_name} ${middle_name.charAt(0)} ${
          middle_name && '.'
        }${last_name.charAt(0)}.`}</div>
      </div>
    }
  >
    <div className={styles.dropdown_container}>
      <div className={styles.full_name}>{`${first_name} ${middle_name} ${last_name}`}</div>
      <div className={styles.email}>{email}</div>
      <Link to='/logout' className={styles.logout}>
        Выйти
      </Link>
    </div>
  </Dropdown>
)

export const Layout = ({ children }: LayoutProps): JSX.Element => {
  const navigate = useNavigate()
  const { data: profileData } = useGetProfileQuery()

  const dispatch = useDispatch()
  const isOpenedNav = useSelector((state: any) => state?.sidebar?.isOpenedNav)

  useEffect(() => {
    profileData && dispatch(setProfileData(profileData))
  }, [profileData])

  return (
    // <SocketProvider>
    <div className={styles.lg_screen_container}>
      <div
        className={classNames(styles.container, {
          [styles.container_closed]: !isOpenedNav,
        })}
      >
        <div
          className={classNames(styles.sidebar_container, {
            [styles.sidebar_container_closed]: !isOpenedNav,
          })}
        >
          <Button
            appearance='container'
            onClick={() => dispatch(setIsOpenedNav(!isOpenedNav))}
            className={classNames(styles.sidebar_btn_opened, {
              [styles.sidebar_btn_closed]: !isOpenedNav,
            })}
          >
            <ArrowSvg />
          </Button>
          <div
            className={classNames({
              [styles.sidebar]: isOpenedNav,
              [styles.sidebar_closed]: !isOpenedNav,
            })}
          >
            <Button
              appearance='container'
              onClick={() => navigate('/')}
              className={classNames(styles.logo_container, {
                [styles.logo_container_closed]: !isOpenedNav,
              })}
            >
              <Logo size='32px'>{isOpenedNav ? 'DARS FSM' : 'D'}</Logo>
            </Button>
            <SideMenuList menuItems={sidebarMenu} />
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles.topbar}>
            {profileData && <DropdownMenu profileData={profileData} />}
          </div>
          {children}
        </div>
      </div>
    </div>
    // </SocketProvider>
  )
}

export const withLayout = <T extends Record<string, unknown>>(Component: FC<T>) => {
  return function withLayoutComponent(props: T): JSX.Element {
    return (
      // ЕСЛИ СЛОМАЮТСЯ СОКЕТЫ ТО УБРАТЬ ТУТ А НЕ В APP
      // <SocketProvider>
      <Layout>
        <Component {...props} />
      </Layout>
      // </SocketProvider>
    )
  }
}
