import styled, { keyframes } from 'styled-components'
import type { ILoader, ILoaderScreenWrapper, ILoaderWrapper } from './Loader.props'

const ringAnimation = keyframes`
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
`

export const Loader = styled.div<ILoader>`
  z-index: 999;

  display: ${({ display }) => display || 'inline-block'};
  align-items: center;

  width: ${({ width }) => width || '80px'};
  height: ${({ height }) => height || '80px'};

  &:after {
    width: ${({ width }) => width || '80px'};
    height: ${({ height }) => height || '80px'};
    margin: 8px;
    display: block;
    content: ' ';
    animation: ${ringAnimation} 1.2s linear infinite;

    border: ${({ borderWidth }) => borderWidth || '6px'} solid ${({ color }) => color || '#4060ff'};

    border-color: ${({ color }) => color || '#4060ff'} transparent
      ${({ color }) => color || '#4060ff'} transparent;
    border-radius: 50%;
  }
`

export const LoaderScreenWrapper = styled.div<ILoaderScreenWrapper>`
  position: absolute;
  z-index: 999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  background: ${({ bg }) => bg || 'transparent'};
`

export const LoaderWrapper = styled.div<ILoaderWrapper>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${({ margin }) => margin};
`
