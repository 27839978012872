import { Button, Dropdown, Input, SubTitle, TextArea, Title } from 'components'
import styles from './ChannelsByIdPage.module.css'
import { useState } from 'react'
import * as React from 'react'
import Modal from '@mui/material/Modal'
import {
  Autocomplete,
  Box,
  TextField,
  Input as InputMui,
  Select,
  InputLabel,
  FormControl,
  MenuItem,
} from '@mui/material'
import {
  useCreateTenantMutation,
  useGetTenantByIdQuery,
  useGetTenantsQuery,
} from 'redux/features/tenats/tenantsApiSlice'
import {
  useAddTenantInFlatMutation,
  useRemoveTenantInFlatMutation,
} from 'redux/features/flats/flatApiSlice'
import { useParams, useRoutes } from 'react-router-dom'
import { ReactComponent as DotsSvg } from 'pages/ApartmentsIdPage/AddTenantModal/img/dots.svg'
import { SureSendNotificationModal } from 'pages/ChannelsPage/SureSendNotificationModal/SureSendNotificationModal'
import { useGetComplexesQuery } from 'redux/features/complexes/complexesApiSlice'
import {
  useEditChannelMutation,
  useGetChannelsByIdQuery,
  useGetChannelsQuery,
} from 'redux/features/channels/channelsApiSlice'

import { withLayout } from 'layout/Layout'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1024,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  padding: '24px',
  borderRadius: '8px',
  border: 'none',
}

const ChannelsByIdPage = () => {
  const [editChannel] = useEditChannelMutation()
  const { data: complexesData } = useGetComplexesQuery({})
  const { channelId } = useParams()

  const { data: channelsByIdData } = useGetChannelsByIdQuery({ id: channelId })

  const { apartmentId } = useParams()

  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const [formState, setFormState] = useState({
    channelId: null,
    complex: [],
    title: null,
    message: '',
  })

  const onChangeBasicInputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    setFormState({
      ...formState,
      [e.target.name]: e.target.value,
    })
  }

  const inputRef = React.useRef<HTMLInputElement>(null)

  const handleFieldChange = (event: any) => {
    console.log(event)
    // event.persist()
    setFormState((formState) => ({
      ...formState,
      [event.target.name]:
        event.target.type === 'checkbox' ? event.target.checked : event.target.value,
    }))
  }

  return (
    <div>
      {/* <Box sx={style}> */}
      <div>
        <div className={styles.header}>
          <Title size='24px'>Уведомление №{channelId}</Title>
          <div className={styles.description}>создано 25.01.2024</div>
        </div>
        <div className={styles.form_container}>
          <div className={styles.form_item}>
            <Title size='16px' margin={'0 0 16px 0'}>
              Получатели
            </Title>

            <FormControl fullWidth>
              {/* <InputLabel id='complex-label' size='small'>
                    ЖК
                  </InputLabel> */}

              {/* <Select
                    disabled={false}
                    size='small'
                    name='complex'
                    labelId='complex-label'
                    id='complex-label'
                    value={formState?.complex}
                    label='ЖК'
                    placeholder='ЖК'
                    onChange={(e: any) =>
                      setFormState({
                        ...formState,
                        [e.target.name]: e.target.value,
                      })
                    }
                  >
                    {complexesData?.map(({ id, name }: any) => (
                      <MenuItem value={id} key={id}>
                        {name}
                      </MenuItem>
                    ))}
                  </Select> */}
              <TextField
                disabled={true}
                select
                // classes={{ root: classes.root }}
                // name='userRoles'
                // id='userRoles'
                // label='userRoles'
                name='complex'
                label='ЖК'
                id='complex-label'
                variant='outlined'
                size='small'
                SelectProps={{
                  multiple: true,
                  value: formState?.complex,
                  // value: formState?.complex,
                  onChange: handleFieldChange,
                }}
              >
                {/* <MenuItem value='admin'>Admin</MenuItem> */}
                {complexesData?.map(({ id, name }: any) => (
                  <MenuItem value={id} key={id}>
                    {name}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </div>
          <div className={styles.form_item}>
            <Title size='16px' margin={'0 0 16px 0'}>
              Тема
            </Title>

            <FormControl fullWidth>
              <InputLabel id='building_id-label' size='small'>
                Текст уведомления
              </InputLabel>

              <Select
                disabled={true}
                size='small'
                name='title'
                labelId='title-label'
                id='title-label'
                value={formState?.title}
                label='Выберите тему'
                placeholder='Выберите тему'
                onChange={(e: any) => {
                  console.log('CH_AN_NEL', e.target)
                  setFormState({
                    ...formState,
                    channelId: e.target.value,
                    [e.target.name]: e.target.value,
                  })
                }}
              >
                {/* {channelsData?.map(({ id, title }: any) => (
                  <MenuItem value={id} key={id}>
                    {title}
                  </MenuItem>
                ))} */}
              </Select>
            </FormControl>
          </div>
          <div className={styles.form_item}>
            <Title size='16px' margin={'0 0 16px 0'}>
              Выберите тему
            </Title>

            <FormControl fullWidth>
              <TextArea
                disabled={true}
                onChange={(e: any) =>
                  setFormState({
                    ...formState,
                    [e.target.name]: e.target.value,
                  })
                }
                size='small'
                name='message'
                id='message-area'
                label='Текст уведомления'
                multiline
                rows={12}
                value={formState?.message || ''}
              />
            </FormControl>
          </div>
        </div>

        {/* <div className={styles.footer}>
          <Button
            appearance='outlined'
            // onClick={handleClose}
            onClick={() => {
              console.log(
                'FO_RM',
                formState?.message == '' || formState.channelId == null,
                formState,
              )
            }}
          >
            Отменить изменения
          </Button>

          <SureSendNotificationModal
            onSubmit={() => {
              console.log(formState)
              editChannel({
                id: formState?.channelId,
                residential_complex: formState?.complex,
                message: formState?.message,
              }).then(() => handleClose())
            }}
            btnTitle={
              <Button
                appearance='primary'
                disabled={formState?.message == '' || formState.channelId == null}
              >
                Отправить уведомление
              </Button>
            }
          />
        </div> */}
      </div>
      {/* </Box> */}
    </div>
  )
}

export default withLayout(ChannelsByIdPage)
