import { Button, ButtonLink } from 'components'
import styles from './Button.module.css'
import { ReactComponent as ReadMoreSvg } from './images/arrow_read_more.svg'

export const ButtonReadMore = ({ link }: { link?: string }): JSX.Element => {
  return (
    <div className={styles.read_more_container}>
      {link ? (
        <ButtonLink appearance='container' to={link}>
          <ReadMoreSvg />
        </ButtonLink>
      ) : (
        <Button appearance='container'>
          <ReadMoreSvg />
        </Button>
      )}
    </div>
  )
}
