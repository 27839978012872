import { apiSlice } from 'redux/api/auth/apiSlice'
import { APIEndpoints } from 'types/api'

type ITenantIDArgs = {
  id: number
  last_name: string
  first_name: string
  middle_name: string
  phone: string
}

type IGetTenantsArgs = {
  query: string
  flats: any
  residential_complex: number
  some: any
}

type ICreateTenantArgs = {
  phone: string
  first_name: string
  last_name: string
  middle_name: string
}

interface IEditTenantArgs extends ICreateTenantArgs {
  id: number
}

export const tenantsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // getTenants: builder.query<IGetTenantsArgs, any>({
    getTenants: builder.query<any, any>({
      query: ({ query, flats, residential_complex }: any) => {
        return {
          url: `${APIEndpoints.TENANTS}`,
          params: {
            query,
            flats,
            residential_complex,
          },
        }
      },
      keepUnusedDataFor: 0,
    }),

    // getTenantById: builder.query<ITenantIDArgs, any>({
    getTenantById: builder.query<any, any>({
      query: ({ id }: ITenantIDArgs) => {
        return {
          url: `${APIEndpoints.TENANTS}${id}`,
        }
      },
    }),

    // getFltasByTenantId: builder.query<ITenantIDArgs, any>({
    getFltasByTenantId: builder.query<any, any>({
      query: ({ id }: ITenantIDArgs) => {
        return {
          url: `${APIEndpoints.TENANTS}${id}/flats/`,
        }
      },
      keepUnusedDataFor: 5,
    }),

    createTenant: builder.mutation<ICreateTenantArgs, any>({
      query: ({ phone, first_name, last_name, middle_name }: ICreateTenantArgs) => ({
        url: `${APIEndpoints.TENANTS}`,
        method: 'POST',
        body: {
          phone,
          first_name,
          last_name,
          middle_name,
        },
      }),
    }),

    editTenant: builder.mutation<IEditTenantArgs, any>({
      query: ({ id, phone, first_name, last_name, middle_name }: IEditTenantArgs) => ({
        url: `${APIEndpoints.TENANTS}${id}/`,
        method: 'PUT',
        body: {
          phone,
          first_name,
          last_name,
          middle_name,
        },
      }),
    }),
  }),
})

export const {
  useGetTenantsQuery,
  useGetTenantByIdQuery,
  useCreateTenantMutation,
  useGetFltasByTenantIdQuery,
  useEditTenantMutation,
} = tenantsApiSlice
