import { store } from 'redux/store'
import { Provider } from 'react-redux'
import { ThemeProvider } from '@mui/material/styles'
import { theme } from 'theme/themeMui'
import { RoutesProvider } from 'routes/provider'
import { SocketProvider } from 'providers/socketProvider/SocketProvider'

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        {/* // ТУТ НЕ ТРОГАЙ В Layout УБЕРИ */}
        <SocketProvider>
          <RoutesProvider />
        </SocketProvider>
      </ThemeProvider>
    </Provider>
  )
}

export default App
