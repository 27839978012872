import { withLayout } from 'layout/Layout'
import { Title, TableSort, LoaderScreenWrapper, Loader, Button, ButtonReadMore } from 'components'
import { useEffect, useState } from 'react'
import styles from './ChannelsPage.module.css'
import { useParams } from 'react-router-dom'
import { CreateNotificationModal } from './CreateNotificationModal/CreateNotificationModal'
import { Badge } from 'components/Badge/Badge'
import {
  useGetChannelsHistoryQuery,
  useGetChannelsQuery,
} from 'redux/features/channels/channelsApiSlice'
import moment from 'moment'

export const getStatus = (ownership_types: any) => {
  if (ownership_types.length == 0) return <Badge appearance='red'>Нет квартиры</Badge>
  return (
    <div className={styles.owner_grid}>
      {ownership_types.map((person: any) => {
        return person == 'GUEST' ? (
          <Badge appearance='orange'>Гость</Badge>
        ) : (
          <Badge appearance='green'>Собственник</Badge>
        )
      })}
    </div>
  )
}

const ChannelsPage = () => {
  const { agentId } = useParams()

  const [searchStr, setSearchStr] = useState<string>('')
  const {
    data: channelsData,
    isSuccess,
    isLoading,
    isError,
    error,
  } = useGetChannelsQuery({ query: searchStr })
  const { data: channelsHistoryData, refetch } = useGetChannelsHistoryQuery({})

  useEffect(() => {
    console.log('chatStatusNew channelsHistoryData', channelsHistoryData)
  }, [channelsHistoryData])

  const [tableData, setTableData] = useState<any>({
    rowsData: [],
    headCells: [],
  })

  useEffect(() => {
    channelsHistoryData &&
      setTableData({
        ...tableData,
        rowsData: channelsHistoryData.map((obj: any) => {
          const { channel, id, message, receiver, responsible, send_time } = obj

          return {
            number: id,
            title: channel,
            created_at: moment(send_time).format('DD MMMM YYYY'),
            getter: receiver,
            txt: message,
            responsible: receiver,
            // arrow_read_more: <ButtonReadMore link={`/channels/${id}`} />,
            // link: `/channels/${id}`, // либо пересоздаём объект без ссылки, либо передаём isEdit in the table
          }
        }),
      })
  }, [channelsHistoryData])

  const headCells = [
    {
      id: 'number',
      numeric: false,
      disablePadding: true,
      label: '#',
    },
    {
      id: 'title',
      numeric: false,
      disablePadding: false,
      label: 'Тема',
    },
    {
      id: 'created_at',
      numeric: false,
      disablePadding: false,
      label: 'Дата создания',
    },
    {
      id: 'getter',
      numeric: false,
      disablePadding: false,
      label: 'Получатели',
    },
    {
      id: 'txt',
      numeric: false,
      disablePadding: false,
      label: 'Текст',
    },
    {
      id: 'responsible',
      numeric: false,
      disablePadding: false,
      label: 'Ответственный',
    },
    // {
    //   hideSortIcon: true,
    //   id: 'arrow_read_more',
    //   numeric: false,
    //   disablePadding: false,
    //   label: '',
    // },
  ]

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchStr(e.target.value)
  }

  if (isLoading)
    return (
      <LoaderScreenWrapper>
        <Loader />
      </LoaderScreenWrapper>
    )
  if (isError) return <div className='loading'>{JSON.stringify(error)}</div>

  return (
    <>
      <div className={styles.title_container}>
        <Title margin='0 0 8px 0'>Уведомления</Title>
        <div className={styles.description}>
          На этой странице можно просматривать, редактировать, создавать сторис и уведомления для
          пользователей приложения DARS App. Просто нажмите на объект, который хотите создать,
          например, “сторис”, а затем на кнопку в правом углу.
        </div>
      </div>
      <div className={styles.action_container}>
        <div className={styles.autocomplete_container}>
          <CreateNotificationModal
            refetch={refetch}
            btnTitle={
              <Button appearance='primary' className={styles.btn_add_action}>
                Создать уведомление
              </Button>
            }
          />
        </div>
      </div>

      <>
        <TableSort rowsData={tableData.rowsData} headCells={headCells} />
      </>
    </>
  )
}

export default withLayout(ChannelsPage)
