import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectCurrentToken } from 'redux/features/auth/authSlice'
import { Loader } from 'components'
import { LoaderScreenWrapper } from 'components/Loader/Loader.styles'

const Public = () => {
  const token = useSelector(selectCurrentToken) || localStorage.token
  const navigate = useNavigate()
  useEffect(() => {
    token ? navigate('/') : navigate('/login')
  }, [token])
  return (
    <LoaderScreenWrapper>
      <Loader />
    </LoaderScreenWrapper>
  )
}

export default Public
