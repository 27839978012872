import styled from 'styled-components'
import type { IHistoryChange } from './HistoryChange.props'

const HistoryChangeStyled = styled.div`
  display: none;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 143%;
  letter-spacing: 0.17px;
  color: #9898a6; // #585858;
  padding: 16px;
  border: 1px solid #c4c4c4;
  border-radius: 8px;
`

export const HistoryChange = ({ placeholder }: IHistoryChange) => {
  return <HistoryChangeStyled>{placeholder}</HistoryChangeStyled>
}
