'use client' // this is a client component 👈🏽
import { TextField } from '@mui/material'
import { styled } from '@mui/material/styles'
import type { TextFieldContainerProps, TextFieldStyledProps } from './SelectGhost.props'

import Select from '@mui/material/Select'

import InputLabel from '@mui/material/InputLabel'

export const InputLabelStyled = styled(InputLabel)(({ theme, focused }) => ({
  // color: focused ? 'orange' : 'inherit', // change the color as desired
}))

export const ErrorMsg = styled('div')(({ theme }) => ({
  fontFamily: 'Montserrat',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '20px',
  fontSize: '13px',
  color: '#E31C1C',
}))

export const SelectFieldWrapper = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'grid',
  gridTemplateColumns: 'auto',
  gap: '4px',
}))

export const SelectFieldContainer = styled('div')<TextFieldContainerProps>(
  ({ theme, error, isValue, focused }: any) => ({
    background: error && 'rgba(227, 28, 28, 0.1) !important',
    width: '100%',
    height: '20px',
    backgroundColor: '#FFFFFF',
    padding: '8px 2px',
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',

    '& label': {
      color: 'purple', // Changes label color when focused
      // top: isValue ? 0 : '-14px',
      // position: isValue && focused ? 'unset' : 'absolute',
      position: 'unset',
    },

    '& label.Mui-focused': {
      position: 'absolute',
      border: '1px solid green', // Changes label color when focused
      color: 'green', // Changes label color when focused
      // top: focused ? 0 : '-14px',
      // top: '-14px',
    },
    '& input': {
      border: '1px solid red',
      paddingLeft: '14px',
    },

    '& select': {
      border: '1px solid red',
      paddingLeft: '14px',
    },

    '&  .MuiInputBase-root .MuiOutlinedInput-root .MuiSelect-root': {
      border: '1px solid red',
      paddingLeft: '14px',
    },

    // .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input

    // "& .MuiSelect-root": {
    // },
    '& .MuiSelect-select': {
      // border: '1px solid red',
      paddingLeft: '14px',
      height: '31px',
      minHeight: ' 1.4375em',
      textOverflow: ' ellipsis',
      whiteSpace: ' nowrap',
      overflow: 'hidden',
      display: 'flex',
      alignItems: 'center',
      // border:  "1px solid",
      // paddingLeft: '14px'
    },
  }),
)

export const SelectStyled = styled(Select)<TextFieldStyledProps>(({ theme, error, focused }) => ({
  '& .MuiOutlinedInput-input': {
    padding: 0,

    '& .MuiOutlinedInput-input': {
      paddingRight: '32px',
      paddingLeft: '14px',
    },

    '& input': {
      marginTop: '15px',
      border: '1px solid red',
    },
  },

  '& fieldset': {
    padding: '0px',
    height: '27px',
    border: 'none',
    '& label': {
      positon: 'absolte',
    },
  },
  '& ::placeholder': {
    color: 'purple',
    positon: 'absolte',
  },
}))
