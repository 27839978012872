// For this component I used 2 libraries...Reference:
// https://react-dropzone.org/#!/Examples
// https://react-dnd.github.io/react-dnd/examples/sortable/simple
// https://codesandbox.io/s/github/react-dnd/react-dnd/tree/gh-pages/examples_ts/04-sortable/simple?from-embed=&file=/src/Container.tsx

import styles from './ClipUploadSingleFile.module.css'
import cn from 'classnames'
import { useDropzone } from 'react-dropzone'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import update from 'immutability-helper'
import { useCallback, useEffect, useRef, useState } from 'react'
import { Card } from './Card'
import { Button } from 'components'
import { ReactComponent as ImgUploadSvg } from './img/img_upload.svg'
// import { ReactComponent as ImgUploadSvg } from './img/img_upload.svg'
import { ReactComponent as ImgUploadStrokeSvg } from './img/img_upload_stroke.svg'
import { ReactComponent as RemoveIcon } from './img/remove.svg'

export const ClipUploadSingleFile = ({ getFileArr, handleSubmit }: any) => {
  const [errImageArr, setErrImageArr] = useState<string[]>([])
  const [files, setFiles] = useState<any>([])
  const [cards, setCards] = useState([])

  useEffect(() => {
    getFileArr(files)
  }, [files])

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/png': [],
      'image/jpeg': [],
      // 'image/*': [],
      'application/pdf': [],
      'application/msword': [],
      'application/docx': [],
      'video/*': [],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [],
      'application/vnd.ms-excel': [],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
      'application/vnd.ms-powerpoint': [],
      'application/vnd.openxmlformats-officedocument.presentationml.presentation': [],
      'text/plain': [],
      'text/html': [],
      'text/css': [],
      'text/javascript': [],
      'application/json': [],
      'application/xml': [],
      'application/zip': [],
      'application/x-rar-compressed': [],
    },

    onDrop: (acceptedFiles: any) => {
      setFiles(
        [
          ...files,
          ...acceptedFiles.map((file: any) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            }),
          ),
        ].map((item: any, i: number) =>
          Object.assign(item, {
            // Set idFile for every new file
            idFile: item.path,
            // idFile: i,
          }),
        ),
      )
    },
  })

  const removeCard = (removeIndex: any) => {
    // xz
  }

  const removeItem = (id: any) => {
    const filtered = files.filter((item: any) => item.idFile != id)
    setFiles(filtered)
    setErrImageArr(errImageArr.filter((item: any) => item != id))
  }

  useEffect(() => {
    files.forEach((file: any, i: any) => {
      const img = new Image()
      img.src = file.preview
      img.onload = () => {
        // if (img.width != 1920 || img.height != 1080) {
        //   setErrImageArr((prev) => [...prev, file.name])
        // }
      }
    })
  }, [files])

  const thumbs = files.map((file: any, i: any) => {
    // console.log('FILEeefile', file.includes('png') || file.includes('jpg'))
    // jpeg
    console.log('FILEeefile', file.type.includes('image'))
    return (
      <div key={file?.name}>
        {file.type.includes('image') ? (
          <img
            src={file.preview}
            className={styles.img_file}
            // Revoke data uri after image is loaded
            onLoad={() => {
              URL.revokeObjectURL(file.preview)
            }}
          />
        ) : (
          <div className={styles.highlight}>
            <ImgUploadStrokeSvg />
            {/* <>Файл</> */}
          </div>
        )}
        <Button
          appearance='container'
          onClick={() => {
            removeItem(file.idFile)
          }}
        >
          <RemoveIcon />
        </Button>
      </div>
    )
  })

  useEffect(() => {
    const previewArr = thumbs.map((item: any) => ({
      id: item.key,
      text: item,
    }))
    setCards(previewArr)
  }, [files, errImageArr])

  const moveCard = useCallback((dragIndex: any, hoverIndex: any) => {
    setCards((prevCards) =>
      update(prevCards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevCards[dragIndex]],
        ],
      }),
    )
  }, [])

  const renderCard = useCallback(
    (card: any, index: any) => {
      return (
        <Card
          className={cn({
            [styles.img_card_err]: errImageArr.includes(card?.id),
          })}
          // className={styles.img_card_err}
          key={card.id}
          index={index}
          id={card.id}
          text={card.text}
          moveCard={moveCard}
        />
      )
    },
    [errImageArr],
  )

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => files.forEach((file: any) => URL.revokeObjectURL(file.preview))
  }, [])

  const [isSendFileDisabled, setIsSendFileDisabled] = useState(false)

  return (
    <section className='container'>
      {/* {files.map((item: any) => <div key={item.path}>{item.path}</div>)} */}
      {/* {files.map((item: any, i: any) => <div key={i}>{item.path}</div>)} */}
      {files.length <= 0 && (
        <div {...getRootProps({ className: 'dropzone' })} className={styles.dropzone_container}>
          <input {...getInputProps()} />
          <ImgUploadSvg />

          <Button
            appearance='primary-light'
            //  onClick={() => removeCard(5)}
          >
            Выберите файлы или перетащите
          </Button>
        </div>
      )}
      {/* <aside style={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 16
      }}>
        {thumbs}
      </aside> */}

      {files.length > 0 && (
        <div className={styles.dnd_container}>
          <DndProvider backend={HTML5Backend}>
            {/* <Container files={thumbs.map((item: any, i: any) => ({
          // indexInContainer: item.key,

          img: item
        }))}
          // setFiles={setFiles}
          // removeCard={removeCard}
          dndInCard={<DndInCard />}
        /> */}

            <div
              style={{
                display: 'grid',
                // gridTemplateColumns: '1fr 1fr 1fr 1fr',
                // gridTemplateColumns: '1fr 1fr',
                gridTemplateColumns: '1fr',
                gap: '16px',
              }}
            >
              {cards.map((card, i) => renderCard(card, i))}
              {/* {dndInCard} */}

              {/* <div
                {...getRootProps({ className: 'dropzone' })}
                className={styles.dropzone_container_card}
              >
                <input {...getInputProps()} />
                <div>
                  <ImgUploadStrokeSvg />
                  <div className={styles.add_img_txt}>Добавь фото</div>
                </div>
              </div> */}
              <Button
                disabled={isSendFileDisabled}
                className={styles.btn_send_file}
                appearance='primary-light'
                onClick={(e) => {
                  handleSubmit(e)
                  setIsSendFileDisabled(!isSendFileDisabled)
                }}
              >
                Отправить
              </Button>
            </div>
          </DndProvider>
        </div>
      )}
      {/* <p
        className={cn({
          [styles.img_card_err_txt]: errImageArr.length > 0,
        })}
      >
        Загрузите файлы форматом 1080×1920 и расставьте в нужном порядке
      </p> */}
      <ul>{/* {errImageArr.map((i:any) => <li key={i}><strong>{i}</strong></li>)}  */}</ul>
    </section>
  )
}
