import styles from './Button.module.css'
import type { ButtonLinkProps } from './Button.props'
import cn from 'classnames'
import { Link } from 'react-router-dom'

export const ButtonLink = ({
  appearance,
  children,
  to,
  className,
  rel,
  target,
  ...props
}: ButtonLinkProps): JSX.Element => {
  return target ? (
    <a
      rel={rel}
      target={target}
      href={to}
      className={cn(
        styles.button_link,
        {
          [styles.container]: appearance == 'container',
          [styles.primary]: appearance == 'primary',
          [styles.primary_light]: appearance == 'primary-light',
          [styles.red_light]: appearance == 'red-light',
          [styles.grey]: appearance == 'grey',
        },
        className,
      )}
    >
      {children}
    </a>
  ) : (
    <Link
      rel={rel}
      to={to}
      className={cn(
        styles.button_link,
        {
          [styles.container]: appearance == 'container',
          [styles.primary]: appearance == 'primary',
          [styles.primary_light]: appearance == 'primary-light',
          [styles.red_light]: appearance == 'red-light',
          [styles.grey]: appearance == 'grey',
        },
        className,
      )}
    >
      {children}
    </Link>
  )
}
