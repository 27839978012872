// https://blog.theashishmaurya.me/how-to-create-a-tag-input-feature-in-reactjs-and-material-ui
import styled from 'styled-components'
import styles from './AutoCompleteTags.module.css'
import type { IInputAutocompleteTags } from './AutoCompleteTags.props'
import { Cancel, Tag } from '@mui/icons-material'
import { FormControl, Stack, TextField, Typography, Autocomplete } from '@mui/material'
import { Box } from '@mui/system'
import { useRef, useState } from 'react'
import { ReactComponent as RemoveSvg } from './images/cross.svg'
import { Button } from 'components/Button/Button'
import { v4 as uuidv4 } from 'uuid'

const RemoveSvgStyled = styled(RemoveSvg)`
  cursor: pointer;
`
const InputWrapper = styled.div`
  .MuiOutlinedInput-root {
    border-radius: 8px;
    /* border: yellow; // #EDF1F6 */
    &:hover {
      border: none !important;
    }
  }

  .MuiOutlinedInput-root {
    border-radius: 8px;

    /* border: #D3D4D3; // #EDF1F6 */
    &:hover {
      border: none !important;
    }
  }

  .MuiChip-filled {
    background-color: rgba(41, 48, 251, 0.1);
  }

  color: green !important;
  .Mui-focused .MuiOutlinedInput-notchedOutline {
    /* border-color: #d3d4d3 !important; */
    border-color: #4060ff !important; // #EDF1F6
  }

  .MuiOutlinedInput-notchedOutline {
    &:hover {
      border-color: #d3d4d3 !important;
    }
  }

  .MuiInputBase-fullWidth {
    &:hover {
      border: none !important;
    }
  }

  .MuiAutocomplete-endAdornment {
    display: none;
  }

  /* .MuiInputLabel {
    color: green !important;
  } */

  /* style: { color: '#fff' }, */
`

const Tags = ({ data, handleDelete }: { data: any; handleDelete: any }) => {
  return (
    <>
      <Box
        sx={{
          // background: '#283240',
          // height: '100%',
          // display: 'flex',
          // padding: '0.4rem',
          // margin: '0 0.5rem 0 0',
          // justifyContent: 'center',
          // alignContent: 'center',
          // color: '#ffffff',

          padding: '8px',
          // padding: '4px 8px',
          width: 'auto',
          // height: '32px',
          background: 'rgba(64, 96, 255, 0.05)',
          borderRadius: '8px',

          fontStyle: 'normal',
          fontWeight: '400',
          fontSize: '14px',
          // lineHeight: '17px',
          color: '#4060FF',

          display: 'flex',
          alignItems: 'center',
          marginTop: '8px',
          marginRight: '8px',
        }}
      >
        <Stack
          direction='row'
          gap={1}
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {/* <Typography>{data}</Typography> */}
          <>{data}</>
          <RemoveSvgStyled
            onClick={() => {
              handleDelete(data)
            }}
          />
        </Stack>
        <Stack
          direction='row'
          gap={1}
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {/* <Typography>{data}</Typography> */}
          {/* <RemoveSvgStyled
          onClick={() => {
            handleDelete(data)
          }}
        /> */}
        </Stack>
      </Box>
      {/* <Button
        appearance='container'
        onClick={() => {
          removeAll()
        }}
      >
        Очистить все
      </Button> */}
    </>
  )
}
export const InputAutocompleteTags = ({
  double_grid_left_elements,
  size,
  options,
  label,
  placeholder,
}: IInputAutocompleteTags) => {
  const [tags, setTags] = useState<any>([])
  const tagRef = useRef<any>()

  // HandleSubmit
  const handleOnSubmit = (e: any) => {
    e.preventDefault()
    if (tagRef.current.value == '') return
    setTags([...tags, tagRef.current.value])
    tagRef.current.value = ''
  }
  const handleDelete = (value: any) => {
    const newtags = tags.filter((val: any) => val !== value)
    setTags(newtags)
  }

  return double_grid_left_elements ? (
    <div>
      <div className={styles.double_grid}>
        {double_grid_left_elements}
        <form onSubmit={handleOnSubmit}>
          <TextField
            inputRef={tagRef}
            autoComplete='off'
            sx={{
              width: '100%',
              '.MuiOutlinedInput-root': {
                '&:hover': {
                  // borderRadius: 50,
                  borderColor: 'red',
                  // borderWidth: 10
                },
              },
            }}
            // {...params}
            // variant='standard' // variant='filled'
            size={size || 'medium'}
            // label={label}
            label={label === undefined ? placeholder : label}
            placeholder={placeholder}
            // placeholder={placeholder}
          />
        </form>
      </div>
      <Box
        sx={{
          margin: '0 .2rem 0 0',
          display: 'flex',
          flexWrap: 'wrap',
          alignItems: 'center',
        }}
      >
        {tags.map((data: any) => {
          return <Tags data={data} handleDelete={handleDelete} key={uuidv4()} />
        })}
        {tags.length != 0 && (
          <Button
            appearance='container'
            onClick={() => setTags([])}
            style={{ color: '#4060FF', marginTop: '8px' }}
          >
            Очистить все
          </Button>
        )}
      </Box>
    </div>
  ) : (
    <>
      <form onSubmit={handleOnSubmit}>
        <TextField
          inputRef={tagRef}
          autoComplete='off'
          sx={{
            width: '100%',
            '.MuiOutlinedInput-root': {
              '&:hover': {
                borderColor: 'red',
              },
            },
          }}
          // {...params}
          // variant='standard' // variant='filled'
          size={size || 'medium'}
          // label={label}
          label={label === undefined ? placeholder : label}
          placeholder={placeholder}
          // placeholder={placeholder}
        />
      </form>
      <Box
        sx={{
          margin: '0 .2rem 0 0',
          display: 'flex',
          flexWrap: 'wrap',
          alignItems: 'center',
        }}
      >
        {tags.map((data: any, index: any) => {
          return <Tags data={data} handleDelete={handleDelete} key={index} />
        })}
        {tags.length != 0 && (
          <Button
            appearance='container'
            onClick={() => setTags([])}
            style={{ color: '#4060FF', marginTop: '8px' }}
          >
            Очистить все
          </Button>
        )}
      </Box>
    </>
  )
}
