import { AccordionSingle, Button } from 'components'
import { useEffect, useState } from 'react'
import styles from './ChatAdditionalInfo.module.css'
import { ReactComponent as ArrowRightIcon } from 'assets/img/arrow_right.svg'
import { ColorDot } from 'components/ColorDot/ColorDot.styles'
import { Divider } from '@mui/material'
import { ReactComponent as CrossSvg } from 'assets/img/cross.svg'
import { CreateOrderModal } from 'pages/OrdersPage/CreateOrderModal/CreateOrderModal'
import { useGetAppealsQuery } from 'redux/features/appeals/appealsApiSlice'
import { useDispatch, useSelector } from 'react-redux'
import { setChatRefFlatData, setCurrentMsgListInStore } from 'redux/features/chats/chatsApiReducer'
import { StatusColor } from 'pages/OrdersPage/CreateOrderModal/constants'

interface Chat {
  name: string
  lastMessage: string
  lastMessageTime: Date
}

interface Props {
  data: any
  initiatedId?: any
  setCurrentChatToNull?: any
}

export const ChatAdditionalInfo = ({ data, initiatedId, setCurrentChatToNull }: Props) => {
  const chatRefFlatData = useSelector((state: any) => state?.chat?.chatRefFlatData)

  const [searchText, setSearchText] = useState('')

  const { data: ordersData, refetch: refetchAppeals } = useGetAppealsQuery({ query: '' })
  // const { data: ordersData, refetch: refetchAppeals } = useGetAppealsQuery({ query: searchText })

  const [ordersToShow, setOrdersToShow] = useState([])

  // useEffect(() => {
  //   console.log('MAX_TEST_searchText', searchText)
  // }, [searchText])

  useEffect(() => {
    if (chatRefFlatData?.id) {
      setOrdersToShow(
        ordersData?.filter(
          ({ target }: any) => target?.flat == chatRefFlatData?.chatsDataFSM?.flat,
        ),
      )
    } else {
      setOrdersToShow(ordersData)
    }
    // Аквамарин Макарова 12 с4 к3, подъезд 2, кв 2
  }, [ordersData, chatRefFlatData?.id])

  const handleSearchTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value)
  }

  // const filteredChats = chats.filter((chat: any) =>
  //   chat.name.toLowerCase().includes(searchText.toLowerCase()),
  // )

  const dispatch = useDispatch()

  return (
    <div className={styles.main_container}>
      {true && (
        <div>
          <div className={styles.top_close_btn_container}>
            <Button
              appearance='container'
              className={styles.btn_close}
              onClick={() => {
                dispatch(setCurrentMsgListInStore([]))
                dispatch(setChatRefFlatData({}))
                setCurrentChatToNull()
              }}
            >
              <CrossSvg />
            </Button>
          </div>

          <div className={styles.additional_info_container}>
            <AccordionSingle title={<span>Активные заявки</span>}>
              <div className={styles.accordion_body_wrapper}>
                {/* {ordersData?.map((orderObj: any) => { */}
                {ordersToShow?.map((orderObj: any) => {
                  const {
                    id,
                    status,
                    // issue_name,
                    // description,
                    // target,
                    // priority,
                    // residential_complex_id,
                    // building_id,
                    // flat_id,
                  } = orderObj

                  return (
                    <CreateOrderModal
                      refetchAppeals={refetchAppeals}
                      // <EditOrderModa
                      key={id}
                      editData={orderObj}
                      isDisabled={false}
                      btnTitle={
                        <Button
                          appearance='container'
                          className={styles.btn_order}
                          key={id}
                          onClick={() => {
                            // console.log('LOGGGG:', {
                            //   id,
                            //   description: description,
                            //   address: target,
                            //   client: target,
                            //   priority: priority,
                            //   status: status,
                            //   residential_complex_id,
                            //   building_id,
                            //   flat_id,
                            //   complex: 3,
                            // })
                            console.log('orderObj', orderObj)
                          }}
                        >
                          <ColorDot style={{ backgroundColor: StatusColor[status] }} />
                          <span>№{id}</span>
                          <ArrowRightIcon />
                        </Button>
                      }
                    />
                  )

                  // <Button appearance='container' className={styles.btn_order} key={id}>
                  //   <ColorDot style={{ backgroundColor: '#2930FB' }} />
                  //   <span>№{id}</span>
                  //   <ArrowRightIcon />
                  // </Button>
                })}
                {/* <Button appearance='container' className={styles.btn_order}>
            <ColorDot style={{ backgroundColor: '#2930FB' }} />
            <span>№2345678</span>
            <ArrowRightIcon />
          </Button>
          <Button appearance='container' className={styles.btn_order}>
            <ColorDot style={{ backgroundColor: 'orange' }} />
            <span>№2345678</span>
            <ArrowRightIcon />
          </Button>
          <Button appearance='container' className={styles.btn_order}>
            <ColorDot style={{ backgroundColor: 'red' }} />
            <span>№2345678</span>
            <ArrowRightIcon />
          </Button> */}
              </div>
            </AccordionSingle>
            <Divider className={styles.divider} />
            {/* 
              <AccordionSingle title={<span>Контакты</span>}>
                <div className={styles.contacts_container}>
                  <ButtonLink appearance='container' to='/tenants/3' className={styles.btn_contact}>
                    <span>Петров Арсений Геннадиевич</span>
                    <ArrowRightIcon />
                  </ButtonLink>
                  <ButtonLink appearance='container' to='/tenants/3' className={styles.btn_contact}>
                    <span>Завьялов Сергей Андреевич</span>
                    <ArrowRightIcon />
                  </ButtonLink>
                  <ButtonLink appearance='container' to='/tenants/3' className={styles.btn_contact}>
                    <span>Петров Арсений Геннадиевич</span>
                    <ArrowRightIcon />
                  </ButtonLink>
                </div>
              </AccordionSingle> */}
          </div>
        </div>
      )}

      {/* <button
        onClick={() =>
          console.log('chatRefFlatData', chatRefFlatData?.chatsDataFSM?.chat_type === 'appeal')
        }
      >
        chatRefFlatData
      </button> */}

      <div className={styles.create_order_btn_container}>
        {/* {chatRefFlatData?.tenants && (  */}
        {/* <h1>{chatRefFlatData?.chatsDataFSM?.chat_type}</h1> */}

        {chatRefFlatData?.chatsDataFSM?.responsible &&
          chatRefFlatData?.chatsDataFSM?.chat_type !== 'appeal' && (
            <CreateOrderModal
              // withAddressFields={true}
              refetchAppeals={refetchAppeals}
              //  currentChat={currentChat}
              btnTitle={
                <Button appearance='primary' className={styles.btn_add_action}>
                  Создать заявку
                </Button>
              }
            />
          )}
      </div>
    </div>
  )
}
