import styles from './Button.module.css'
import type { ButtonProps } from './Button.props'
import cn from 'classnames'

export const Button = ({ appearance, children, className, ...props }: ButtonProps): JSX.Element => {
  return (
    <button
      className={cn(
        styles.button,
        {
          [styles.container]: appearance == 'container',
          [styles.primary]: appearance == 'primary',
          [styles.outlined]: appearance == 'outlined',
          [styles.primary_light]: appearance == 'primary-light',
          [styles.grey]: appearance == 'grey',
          [styles.red_light]: appearance == 'red-light',
          [styles.black]: appearance == 'black',
          [styles.ghost]: appearance == 'ghost',
        },
        className,
      )}
      {...props}
    >
      {children}
    </button>
  )
}
