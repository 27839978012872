import styles from './AccordionSingle.module.css'
import type { AccordionSingleProps } from './AccordionSingle.props'
import cn from 'classnames'
import { Button } from 'components/Button/Button'
import { ReactComponent as ArrowSvg } from 'assets/img/arrow_accordion.svg'
import { useState } from 'react'

export const AccordionSingle = ({
  children,
  title,
  className,
}: AccordionSingleProps): JSX.Element => {
  const [isActive, setIsActive] = useState(false)
  return (
    <>
      <Button
        appearance='container'
        onClick={() => setIsActive(!isActive)}
        className={styles.accordion_title}
      >
        {title}
        <ArrowSvg />
      </Button>
      <div className={cn(styles.accordion_body, { [styles.accordion_body_active]: isActive })}>
        {isActive && children}
      </div>
    </>
  )
}
