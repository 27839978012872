import { Button, Dropdown, Input, SubTitle, TextArea, Title } from 'components'
import styles from './SureSendNotificationModal.module.css'
import { useState } from 'react'
import * as React from 'react'
import Modal from '@mui/material/Modal'
import {
  Autocomplete,
  Box,
  TextField,
  Input as InputMui,
  Select,
  InputLabel,
  FormControl,
  MenuItem,
} from '@mui/material'
import {
  useCreateTenantMutation,
  useGetTenantByIdQuery,
  useGetTenantsQuery,
} from 'redux/features/tenats/tenantsApiSlice'
import {
  useAddTenantInFlatMutation,
  useRemoveTenantInFlatMutation,
} from 'redux/features/flats/flatApiSlice'
import { useParams } from 'react-router-dom'
import { ReactComponent as DotsSvg } from 'pages/ApartmentsIdPage/AddTenantModal/img/dots.svg'

const style = {
  boxSizing: 'border-box',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 378,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  padding: '24px',
  borderRadius: '8px',
  border: 'none',
}

export const SureSendNotificationModal = ({
  btnTitle,
  onSubmit,
}: {
  btnTitle: React.ReactElement
  onSubmit: () => void
}) => {
  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  return (
    <div>
      <div onClick={handleOpen}>{btnTitle}</div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        {/* <Box sx={style} component='form' noValidate autoComplete='off'> */}
        <Box sx={style}>
          <div>
            <div className={styles.header}>
              <Title size='18px'>Убедитесь, что</Title>

              <ul className={styles.check_list}>
                <li>
                  <Title size='14px' className={styles.description}>
                    Выбраны правильные получатели
                  </Title>
                </li>
                <li>
                  <Title size='14px' className={styles.description}>
                    Верно выбрана тема уведомления
                  </Title>
                </li>
                <li>
                  <Title size='14px' className={styles.description}>
                    В тексте нет грамматических и орфографических ошибок{' '}
                    <span>
                      (в идеале вы прогнали текст в сервисе для проверки грамматики и пунктуации)
                    </span>
                  </Title>
                </li>
              </ul>
            </div>
            <div className={styles.form_container}></div>

            <div className={styles.footer}>
              <Button
                appearance='primary'
                onClick={onSubmit}
                // disabled={
                //   isFound ||
                //   formState.last_name == '' ||
                //   formState.first_name == '' ||
                //   formState.middle_name == ''
                // }
              >
                Все верно
              </Button>
              <Button appearance='outlined' onClick={handleClose}>
                Отменить изменения
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  )
}
