import React, { useEffect, useState } from 'react'
import styles from './ChatList.module.css'
import { ReactComponent as SearchIcon } from 'assets/img/search.svg'
import moment from 'moment'
import {
  setChatRefFlatData,
  setIsActiveChatsStore,
  setMsgSeen,
  setResetMsgList,
  setSearchWordChatList,
} from 'redux/features/chats/chatsApiReducer'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'
import { SelectGhost } from 'components/Input/SelectGhost/SelectGhost'
import { useInView } from 'react-intersection-observer'
import { useSocket } from 'providers/socketProvider/useSocket'
import { PAGE_SIZE_PREVIEW_IN_CHAT } from 'pages/ChatPage/ChatPage'
import { ChatStatus } from 'redux/features/chats/chatsApiSlice'
import { useGetProfileQuery } from 'redux/features/profile/profileApiSlice'

interface Chat {
  name: string
  lastMessage: string
  lastMessageTime: Date
}

interface Props {
  chats: Chat[]
}

const optionsStatus = [
  {
    id: 1,
    // value: true,
    value: ChatStatus.OPEN,
    title: 'Активные чаты',
  },
  {
    id: 2,
    // value: false,
    value: ChatStatus.CLOSED,
    title: 'Неактивные чаты',
  },
]

export const ChatList = ({
  currentChatId,
  // flatsData,
  chats,
  setCurrentChat,
  setCurrentChatName,
  getCurrentChatMessages,
}: // setMessageSeen,
any) => {
  // TODO: FIX DEBOUNCE
  // const debounced = useDebounce(searchText, 500)
  // const { data: chatsData } = useGetChatsListQuery({
  //   query: debounced,
  // })
  const { data: profileData } = useGetProfileQuery()

  const dispatch = useDispatch()
  // const [isActiveChatsStore, setIsActiveChats] = useState('true')
  const searchWordChatList = useSelector((state: any) => state?.chat?.searchWordChatList)
  const isActiveChatsStore = useSelector((state: any) => state?.chat?.is_active_chats)
  const triggerPage = useSelector((state: any) => state?.chat?.triggerPage)
  const totalPagesOnSockets = useSelector((state: any) => state?.chat?.totalPagesOnSockets)

  const [searchText, setSearchText] = useState<string>('')

  const handleSearchTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value)

    dispatch(setSearchWordChatList(event.target.value))
  }

  const { socketRef } = useSocket()
  const [page, setPage] = useState(1)
  const { ref, inView, entry } = useInView({
    rootMargin: '100px',
    threshold: 0.01,
  })

  const [inViewTrigger, setInViewTrigger] = useState(true)

  // useEffect(() => {
  //   console.log('555_triggerPage', triggerPage)
  // }, [inView, chats.length, triggerPage])

  useEffect(() => {
    // setInViewTrigger(false)

    if (page <= totalPagesOnSockets) {
      if (inView && chats.length > 0) {
        console.log('555_TE_SAM_chats', chats)
        // socketRef?.emit('preview', { page: page + 1, page_size: 2 })
        socketRef?.emit('preview', { page: page + 1, page_size: PAGE_SIZE_PREVIEW_IN_CHAT })
        setPage(page + 1)
      }
    }
    // }, [inView]) // TODO: CHECK IT
  }, [inView, chats.length, triggerPage])

  const chatsDataSearch = useSelector((state: any) => state?.chat?.chatsDataSearch)

  useEffect(() => {
    console.log('TE_SAM_chats', chats)
  }, [chats])

  return (
    <div className={styles.chat_list_container}>
      {/* <div className={styles.chat_search_row} style={{ opacity: 1, padding: '0 18px' }}> // TODO ВЕРНУТЬ когда буду фильтры */}
      <div className={styles.chat_search_row} style={{ opacity: 0, padding: '0 18px' }}>
        <SelectGhost
          onChangeHandler={(value: any) => {
            // setIsActiveChats(value)
            // console.log('setIsActiveChatsStore', value)
            dispatch(setIsActiveChatsStore(value))
          }}
          options={optionsStatus}
          defaultSelected={optionsStatus[0].value}
        />
      </div>
      {/* {isActiveChatsStore} */}

      <ul className={styles.chat_list}>
        <div className={styles.chat_search_row} style={{ display: 'none' }}>
          <div className={styles.chat_search_input_container}>
            <SearchIcon />
            <input
              name='search'
              placeholder='Поиск по названию чата'
              value={searchText}
              onChange={handleSearchTextChange}
            />
          </div>
        </div>

        {/* <button
          onClick={() => {
            socketRef?.emit('preview', { page: page + 1, page_size: 2 })
            setPage(page + 1)
          }}
        >
          {totalPagesOnSockets} PAGE: {page}
        </button> */}

        {searchWordChatList.length != ''
          ? chatsDataSearch
              // ?.filter((chat: any) => {
              //   return chat?.chatsDataFSM?.chat_type !== 'channel'
              // })
              // TODO: UNCOMMENT TO FILTER
              // ?.filter((chat: any) => {
              //   return chat?.chatsDataFSM?.title.toLowerCase().includes(searchText)
              // })
              // ?.filter((chat: any) => {
              //   // console.log('chat?.is_active###', chat?.chatsDataFSM?.status)
              //   return chat?.chatsDataFSM?.status == isActiveChatsStore ? true : false
              // })
              ?.map((chat: any, indx: number) => {
                // const currentFlatData = flatsData?.filter((obj: any) =>
                //   obj.chat_ref.includes(chat?.id.toString()),
                // )[0]
                return (
                  <li
                    key={chat?.id}
                    className={classNames(styles.chat_item, {
                      [styles.chat_item_active]: chat?.id == currentChatId,
                    })}
                    onClick={() => {
                      console.log('GGGGOO888', chat)

                      setCurrentChat(chat?.id)
                      if (chat?.id == currentChatId) return null
                      dispatch(setResetMsgList())
                      setCurrentChatName(chat?.title)
                      dispatch(setMsgSeen(chat?.id))

                      // chat?.last_message?.id && setMessageSeen(chat?.last_message.id) // TODO CHECK IF IT WORKS WITOUT THIS LINE
                      if (chat?.last_message?.id) {
                        socketRef?.emit('message_seen', { message_id: chat?.last_message.id })
                        // socketRef?.emit('preview', { page: 1, page_size: 100 })
                      }

                      dispatch(
                        setChatRefFlatData({
                          id: chat?.chatsDataFSM?.id,
                          chatsDataFSM: chat?.chatsDataFSM,
                          isOpen: true,
                        }),
                      )

                      getCurrentChatMessages(1)
                      // getCurrentChatMessages(chat?.id, 1)
                    }}
                  >
                    <div className={styles.chat_row}>
                      <div className={styles.chat_item_name}>{chat?.title}</div>
                      {/* <div className={styles.chat_item_name}>{chat?.id}</div> */}
                      {/* <div className={styles.chat_item_name}>{currentChatId}</div> */}

                      <div className={styles.chat_item_date}>
                        {moment(chat?.last_message?.sent_at).format('HH:mm')}
                      </div>
                    </div>
                    <div className={styles.chat_row}>
                      <div className={styles.chat_item_msg}>
                        {chat?.last_message ? chat?.last_message.text : ''}
                      </div>
                      {chat?.unseen_messages_count > 0 && (
                        <div className={styles.chat_item_msg_num}>
                          {chat?.unseen_messages_count}
                        </div>
                      )}
                    </div>
                  </li>
                )
              })
          : chats
              // TODO: UNCOMMENT TO FILTER
              //         // ?.filter((chat: any) => {
              //         //   return chat?.chatsDataFSM?.title.toLowerCase().includes(searchText)
              //         // })
              //         // ?.filter((chat: any) => {
              //         //   return chat?.chatsDataFSM?.chat_type !== 'channel'
              //         // })
              // ?.filter((chat: any) => {   // TODO ВЕРНУТЬ когда буду фильтры
              //   return chat?.chatsDataFSM?.status == isActiveChatsStore ? true : false
              // })
              // ?.filter((chat: any) => {  // TODO ВЕРНУТЬ когда буду фильтры
              //   console.log('chatStatusNew chat kakoyto', chat)
              //   if (isActiveChatsStore == 'OPEN') {
              //     return (
              //       chat?.chatsDataFSM?.responsible === Number(profileData?.id) ||
              //       chat?.chatsDataFSM?.responsible == null
              //     )
              //   } else {
              //     return chat?.chatsDataFSM?.responsible !== Number(profileData?.id)
              //   }
              // })
              ?.map((chat: any, indx: number) => {
                return (
                  <li
                    key={chat?.id}
                    className={classNames(styles.chat_item, {
                      [styles.chat_item_active]: chat?.id == currentChatId,
                    })}
                    onClick={() => {
                      console.log('GGGGOO888', chat?.chatsDataFSM?.chat_type)
                      setCurrentChat(chat?.id)
                      if (chat?.id == currentChatId) return null
                      dispatch(setResetMsgList())
                      setCurrentChatName(chat?.title)
                      dispatch(setMsgSeen(chat?.id))

                      // chat?.last_message?.id && setMessageSeen(chat?.last_message.id) // TODO CHECK IF IT WORKS WITOUT THIS LINE
                      if (chat?.last_message?.id) {
                        socketRef?.emit('message_seen', { message_id: chat?.last_message.id })
                        // socketRef?.emit('preview', { page: 1, page_size: 100 })
                      }

                      dispatch(
                        setChatRefFlatData({
                          id: chat?.chatsDataFSM?.id,
                          chatsDataFSM: chat?.chatsDataFSM,
                          isOpen: true,
                        }),
                      )

                      getCurrentChatMessages(1)
                      // getCurrentChatMessages(chat?.id, 1)
                    }}
                  >
                    <div className={styles.chat_row}>
                      <div className={styles.chat_item_name}>{chat?.chatsDataFSM?.title}</div>
                      {/* <div className={styles.chat_item_name}>
                        {chat?.chatsDataFSM?.responsible
                          ? `${chat?.chatsDataFSM?.responsible} - ${profileData?.id}`
                          : `Net - ${profileData?.id}`}
                      </div> */}
                      {/* <div className={styles.chat_item_name}>{profileData?.id}</div> */}
                      <div className={styles.chat_item_date}>
                        {moment(chat?.last_message?.sent_at).format('HH:mm')}
                      </div>
                    </div>
                    <div className={styles.chat_row}>
                      <div className={styles.chat_item_msg}>
                        {chat?.last_message ? chat?.last_message.text : ''}
                      </div>
                      {chat?.unseen_messages_count > 0 && (
                        <div className={styles.chat_item_msg_num}>
                          {chat?.unseen_messages_count}
                        </div>
                      )}
                    </div>
                  </li>
                )
              })}

        <h1 ref={ref} style={{ opacity: 0 }}>
          Page: {page} caht length: {chats.length} count msg: {inView ? 'true' : 'false'}
        </h1>
      </ul>
    </div>
  )
}
