/* eslint-disable autofix/no-unused-vars */
/* eslint-disable  @typescript-eslint/no-explicit-any*/
import * as React from 'react'
import { Box } from '@mui/material'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import { visuallyHidden } from '@mui/utils'
import styled from 'styled-components'
import { useNavigate, useParams } from 'react-router-dom'
import { InputInnerLabel } from 'components/Input/InputInnerLabel/InputInnerLabel'

import { ReactComponent as EditSvg } from 'pages/IpuIdPage/img/edit.svg'
import { ReactComponent as ApplySvg } from 'pages/IpuIdPage/img/apply.svg'
import { Button } from 'components/Button/Button'
import { useUpdFlatMetersMutation, useUpdFlatMutation } from 'redux/features/flats/flatApiSlice'

const TableCellStyled = styled(TableCell)`
  &&& {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #5e5e5e;
    text-align: left;
    border-bottom: 1px solid #edf1f6;
    span {
      &::first-letter {
        text-transform: capitalize;
      }
    }

    &:last-child {
      border-bottom: none;
    }
  }
`

const TableCellBodyStyled = styled(TableCell)`
  &&& {
    max-width: ${({ width }: { width?: string }) => width || '100%'};
    min-width: ${({ width }: { width?: string }) => width || '100%'};
    border-bottom: 1px solid #edf1f6;
    text-align: left;
  }
`

interface Data {
  constructions: string
  levels: number
  apartments: number
  tenants: number
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

type Order = 'asc' | 'desc'

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort<T>(array: any, comparator: (a: T, b: T) => number) {
  // function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el: any, index: any) => [el, index] as [T, number])
  // const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a: any, b: any) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) {
      return order
    }
    return a[1] - b[1]
  })
  return stabilizedThis.map((el: any) => el[0])
}

interface EnhancedTableProps {
  numSelected: number
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void
  order: Order
  orderBy: string
  rowCount: number
  headCells: any
}

const TableHeadStyled = styled(TableHead)`
  padding: 4px;
`

interface ITableContainerStyled {
  islinked?: boolean
}

const TableContainerStyled = styled(TableContainer)<ITableContainerStyled>`
  border: 1px solid #edf1f6;
  border-radius: 8px;
  color: #5e5e5e;
  background: #f8f8f8;
  &:last-child {
    border-bottom: none;
  }

  .MuiTableCell-root {
    padding-left: 16px;
    padding-right: 16px;
  }

  .MuiTableHead {
    tr {
      cursor: pointer;
      background: red;
    }
    &:hover {
      cursor: none;
      background: red;
    }
  }

  .MuiTableRow-root {
    border-bottom: 1px solid #edf1f6;

    /* &:last-child {
      border-bottom: none;
    } */

    td a {
      opacity: ${({ islinked }) => (islinked ? 0 : 1)};
    }
    &:hover {
      cursor: pointer;
      transition: all ease-in-out 0.3s;
      background: #f7f8fc;
      td a {
        opacity: 1;
      }
    }
  }

  .MuiTableRow-head {
    height: 15px;
    box-sizing: border-box;
    border-bottom: 1px solid #edf1f6;

    background: transparent;
    &:hover {
      cursor: default;
      transition: all ease-in-out 0.3s;
      background: transparent;
    }
  }
`

function EnhancedTableHead(props: EnhancedTableProps) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props
  const createSortHandler = (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property)
  }

  return (
    <TableHeadStyled>
      <TableRow>
        {props.headCells.map((headCell: any, index: number) => (
          <TableCellStyled
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={index == 0 ? 'none' : headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            color={'#9898A6'}
          >
            {headCell.sortable ? (
              <TableSortLabel
                hideSortIcon={headCell.hideSortIcon}
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
                style={{ width: 'max-content' }}
              >
                <span>{headCell.label}</span>
                {orderBy === headCell.id ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            ) : (
              <span>{headCell.label}</span>
            )}
          </TableCellStyled>
        ))}
      </TableRow>
    </TableHeadStyled>
  )
}

export const TableSortEditable = ({
  refetch,
  flatData,
  rowsData,
  headCells,
  onChangeEdit,
}: {
  refetch: any
  flatData: any
  rowsData: any
  headCells: any
  onChangeEdit: any
}) => {
  const [islinked, setislinked] = React.useState<boolean>(false)
  const [rows, setRows] = React.useState<any[]>([])

  React.useEffect(() => {
    setRows([])

    setislinked(rowsData?.length > 0 && Object.keys(rowsData[0]).includes('link'))
    setRows([...rowsData.map((obj: any) => Object.values(obj))])
  }, [rowsData])

  const [order, setOrder] = React.useState<Order>('asc')
  const [orderBy, setOrderBy] = React.useState<keyof Data>('levels')
  const [selected, setSelected] = React.useState<readonly any[]>([])
  // const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(100)

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = rows.map((row) => Object.values(row)[0])
      // const newSelected = rows.map((n) => n.constructions);
      setSelected(newSelected)
      return
    }
    setSelected([])
  }

  const handleClick = (event: React.MouseEvent<unknown>, name: any) => {
    const selectedIndex = selected.indexOf(name)
    let newSelected: readonly string[] = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      )
    }

    setSelected(newSelected)
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const isSelected = (name: any) => selected.indexOf(name) !== -1

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0

  return (
    <Box sx={{ width: '100%' }}>
      {/* <Paper sx={{ width: '100%', mb: 2 }}> */}
      <TableContainerStyled islinked={islinked}>
        <Table sx={{ minWidth: 750 }} aria-labelledby='tableTitle' size={'medium'}>
          <EnhancedTableHead
            headCells={headCells}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
          />
          <TableBody>
            {stableSort(rows, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row: any, index: number) => {
                const isItemSelected = isSelected(row.constructions)
                const labelId = `enhanced-table-checkbox-${index}`
                return (
                  <TableItem
                    refetch={refetch}
                    flatData={flatData}
                    handleClick={handleClick}
                    key={row.id}
                    row={row}
                    index={index}
                    onChangeEdit={onChangeEdit}
                    isItemSelected={isItemSelected}
                  />
                )
              })}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: 53 * emptyRows,
                }}
              >
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainerStyled>
    </Box>
  )
}

const TableItem = ({
  flatData,
  refetch,
  handleClick,
  row,
  onChangeEdit,
  isItemSelected,
  index,
}: any) => {
  const [isEditable, setIsEditable] = React.useState<boolean>(false)
  const [updFlatMeters] = useUpdFlatMetersMutation()
  const [changeValueState, setChangeValueState] = React.useState('')

  return (
    <TableRow
      // hover
      style={{ height: '80px' }}
      onClick={(event: React.MouseEvent<unknown>) => {
        handleClick(event, Object.values(row)[0])
      }}
      role='checkbox'
      aria-checked={isItemSelected}
      tabIndex={-1}
      key={`${Object.values(row)[index]}+${index}`} // KEY IS IMMPORTANT, OTHERWISE TABLE WILL BE MAD
      selected={isItemSelected}
    >
      {Object.values(row).map((item: any, index) => {
        if (index == 0) return <></>

        if (index == row.length - 2) {
          return isEditable ? (
            <TableCellBodyStyled align='right' key={row.id}>
              <InputInnerLabel
                type='number'
                key={row.id}
                placeholder='Введите значение'
                label='куб.м'
                value={parseInt(item)}
                onChange={(e: any) => {
                  if (isNaN(e.target.value) || e.target.value == 'e') return
                  setChangeValueState(e.target.value)
                  onChangeEdit(e, row[0])
                }}
              />
            </TableCellBodyStyled>
          ) : (
            <TableCellBodyStyled width='200px' align='right' key={row.id}>
              {item}
            </TableCellBodyStyled>
          )
        }

        if (index == row.length - 1) {
          return (
            <TableCellBodyStyled align='right' key={row.id}>
              <Button
                appearance='container'
                onClick={() => {
                  if (!isEditable) {
                    setIsEditable(!isEditable)
                  } else {
                    updFlatMeters({
                      value: changeValueState,
                      id: row[0],
                      flat_pk: flatData.id,
                    })
                    // .then(() => refetch())
                    setIsEditable(!isEditable)
                  }
                }}
                key={row.id}
              >
                {isEditable ? <ApplySvg /> : <EditSvg />}
              </Button>
            </TableCellBodyStyled>
          )
        }

        return (
          <TableCellBodyStyled align='right' key={row.id}>
            {item}
          </TableCellBodyStyled>
        )
      })}
    </TableRow>
  )
}
