import { createSlice } from '@reduxjs/toolkit'

/* Reducers  */
const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState: {
    isOpenedNav: true,
  },
  reducers: {
    setIsOpenedNav: (state, action) => {
      state.isOpenedNav = action.payload
    },
  },
})

export const { setIsOpenedNav } = sidebarSlice.actions
export default sidebarSlice.reducer

// Selectors
export const isOpenedNav = (state: any) => state.sidebar.isOpenedNav
