import TextField from '@mui/material/TextField'
import { Autocomplete } from '@mui/material'
import type { IInputAutocomplete } from './AutoComplete.props'

export const InputAutocomplete = ({
  size,
  inputValue,
  value,
  id = 'combo-box-demo',
  name,
  options,
  placeholder,
  variant,
  onChange,
}: IInputAutocomplete) => {
  return (
    <Autocomplete
      // disablePortal
      inputValue={inputValue}
      value={value}
      id={id}
      options={options}
      size={size || 'medium'}
      onChange={(event: any, newInputValue: any) => onChange(name, newInputValue)}
      onInputChange={(event: any, newInputValue: any) => onChange(name, newInputValue)}
      renderInput={(params: any) => (
        <TextField {...params} name={name} variant={variant} label={placeholder} />
      )}
    />
  )
}
