import styled from 'styled-components'
import type { SubTitleProps, TitleProps } from './Title.props'

export const Title = styled.div<TitleProps>`
  display: block;
  font-style: normal;
  font-weight: ${({ weight }) => weight || 600};
  margin: ${({ margin }) => margin || 0};
  font-size: ${({ size }) => size || '28px'};
  letter-spacing: 0.15px;
  color: ${({ color }) => color || 'rgba(0, 0, 0, 0.87)'};
`

export const SubTitle = styled.div<SubTitleProps>`
  font-style: normal;
  font-weight: ${({ weight }) => weight || 600};
  font-size: ${({ size }) => size || '17px'};
  line-height: 34px;
  letter-spacing: 0.15px;
  color: ${({ color }) => color || '#4060FF'};
`
