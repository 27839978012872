import { useState, ReactElement } from 'react'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import Stack from '@mui/material/Stack'
import { DesktopDateRangePicker } from '@mui/x-date-pickers-pro/DesktopDateRangePicker'
import styled from 'styled-components'
import { Button } from 'components'

interface DatePickerBtnProps {
  isBtnActive: boolean
}

const DatePickerBtn = styled(Button)<DatePickerBtnProps>`
  height: auto;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 29px;
  text-transform: uppercase;

  color: ${({ isBtnActive }) => (isBtnActive ? '#4060FF' : '#4a4a4a')};
`

interface DateRangeProps {
  isBtnActive: boolean
  btnTitle?: string | ReactElement
  // onChangeDate?: (date: Date[], keyboardInputValue?: string | undefined) => void
  // onChangeDate?: (val: Date) => void
  onChangeDate?: (val: any) => void
  // rangeType?: any
}

export const DateRangePicker = ({
  // rangeType,
  isBtnActive = true,
  btnTitle,
  onChangeDate,
}: DateRangeProps) => {
  const [value, setValue] = useState<[Date | null, Date | null]>([null, null])
  const [open, setOpen] = useState(false)

  // useEffect(() => {
  //   if (rangeType != 'CUSTOM') setValue([null, null])
  // }, [rangeType])

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Stack spacing={3}>
          <DesktopDateRangePicker
            //  ref={ref}
            // open={true}
            open={open}
            onClose={() => setOpen(false)}
            disableOpenPicker={true}
            startText='Desktop start'
            value={value}
            // onChange={onChangeDate}
            onChange={(newValue: [Date | null, Date | null]) => {
              setValue([null, null])
              onChangeDate?.(newValue)
              setValue(newValue)
            }}
            renderInput={() => (
              // renderInput={(startProps: any, endProps: any) => (
              <>
                <DatePickerBtn
                  isBtnActive={isBtnActive}
                  appearance='container'
                  onClick={() => setOpen(!open)}
                >
                  {btnTitle ? btnTitle : <div>Custom</div>}
                </DatePickerBtn>
              </>
            )}
          />
        </Stack>
      </LocalizationProvider>
    </>
  )
}
