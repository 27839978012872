import styles from './Breadcrumb.module.css'
import type { BreadcrumbItem, BreadcrumbProps, BreadcrumbStyledProps } from './Breadcrumb.props'
import cn from 'classnames'
import styled from '@emotion/styled'
import { Link } from 'react-router-dom'

const BreadcrumbStyled = styled.div<BreadcrumbStyledProps>`
  margin: ${({ margin }: { margin?: string }) => margin};
`

export const Breadcrumb = ({ items, className, margin, state }: BreadcrumbProps) => {
  return (
    <BreadcrumbStyled margin={margin}>
      <ul className={cn(styles.breadcrumb, className)}>
        {state
          ? items.map((item: BreadcrumbItem, index: number) => {
              return index == items.length - 1 ? (
                <li key={item.link}>{item.title}</li>
              ) : (
                <li key={item.link}>
                  <Link
                    to={{
                      pathname: item.link,
                    }}
                    state={{ ...state }}
                  >
                    {item.title}
                  </Link>
                </li>
              )
            })
          : items.map((item: BreadcrumbItem, index: number) => {
              return index == items.length - 1 ? (
                <li key={item.link}>{item.title}</li>
              ) : (
                <li key={item.link}>
                  <a href={item.link}>{item.title}</a>
                </li>
              )
            })}
      </ul>
    </BreadcrumbStyled>
  )
}
