import styles from './Badge.module.css'
import type { BadgeProps } from './Badge.props'
import cn from 'classnames'

export const Badge = ({ appearance, children, className, ...props }: BadgeProps): JSX.Element => {
  return (
    <div
      className={cn(
        styles.badge,
        {
          [styles.green]: appearance == 'green',
          [styles.orange]: appearance == 'orange',
          [styles.red]: appearance == 'red',
          [styles.blue]: appearance == '#2930FB',
          [styles.orange]: appearance == '#FF8C19',
          [styles.green]: appearance == '#34C759',
          [styles.grey]: appearance == '#626F84',
        },
        className,
      )}
      {...props}
    >
      {children}
    </div>
  )
}
