import { Button, Dropdown, Input, SubTitle, TextArea, Title } from 'components'
import styles from './CreateNotificationModal.module.css'
import { useState } from 'react'
import * as React from 'react'
import Modal from '@mui/material/Modal'
import {
  Box,
  TextField,
  Input as InputMui,
  Select,
  InputLabel,
  FormControl,
  MenuItem,
} from '@mui/material'

import { SureSendNotificationModal } from 'pages/ChannelsPage/SureSendNotificationModal/SureSendNotificationModal'
import { useGetComplexesQuery } from 'redux/features/complexes/complexesApiSlice'
import {
  useEditChannelMutation,
  useGetChannelsQuery,
} from 'redux/features/channels/channelsApiSlice'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1024,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  padding: '24px',
  borderRadius: '8px',
  border: 'none',
}

export const CreateNotificationModal = ({
  btnTitle,
  refetch,
}: {
  btnTitle: React.ReactElement
  refetch: any
}) => {
  const { data: complexesData } = useGetComplexesQuery({})
  const { data: channelsData } = useGetChannelsQuery({})
  const [editChannel] = useEditChannelMutation()
  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const [formState, setFormState] = useState({
    channelId: null,
    complex: [],
    title: null,
    message: '',
  })

  const onChangeBasicInputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    setFormState({
      ...formState,
      [e.target.name]: e.target.value,
    })
  }

  const [isFound, setIsFound] = useState(true)

  const inputRef = React.useRef<HTMLInputElement>(null)

  React.useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }, [isFound])

  const handleFieldChange = (event: any) => {
    // event.persist()
    setFormState((formState) => ({
      ...formState,
      [event.target.name]:
        event.target.type === 'checkbox' ? event.target.checked : event.target.value,
    }))
  }

  return (
    <div>
      <div onClick={handleOpen}>{btnTitle}</div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <div>
            <div className={styles.header}>
              <Title size='24px'>Создать уведомление</Title>
              <div className={styles.description}>
                Обратите внимание на то, что после отправки уведомления, его редактирование или
                удаление будет недоступно
              </div>
            </div>
            <div className={styles.form_container}>
              <div className={styles.form_item}>
                <Title size='16px' margin={'0 0 16px 0'}>
                  Выберите получателей
                </Title>

                <FormControl fullWidth>
                  <TextField
                    select
                    name='complex'
                    label='ЖК'
                    id='complex-label'
                    variant='outlined'
                    size='small'
                    SelectProps={{
                      multiple: true,
                      value: formState?.complex,
                      onChange: handleFieldChange,
                    }}
                  >
                    {complexesData?.map(({ id, name }: any) => (
                      <MenuItem value={id} key={id}>
                        {name}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormControl>
              </div>
              <div className={styles.form_item}>
                <Title size='16px' margin={'0 0 16px 0'}>
                  Выберите тему
                </Title>

                <FormControl fullWidth>
                  <InputLabel id='building_id-label' size='small'>
                    Выберите тему
                  </InputLabel>

                  <Select
                    disabled={false}
                    size='small'
                    name='title'
                    labelId='title-label'
                    id='title-label'
                    value={formState?.title}
                    label='Выберите тему'
                    placeholder='Выберите тему'
                    onChange={(e: any) => {
                      setFormState({
                        ...formState,
                        channelId: e.target.value,
                        [e.target.name]: e.target.value,
                      })
                    }}
                  >
                    {channelsData?.map(({ id, title }: any) => (
                      <MenuItem value={id} key={id}>
                        {title}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className={styles.form_item}>
                <Title size='16px' margin={'0 0 16px 0'}>
                  Введите текст уведомления
                </Title>

                <FormControl fullWidth>
                  <TextArea
                    onChange={(e: any) =>
                      setFormState({
                        ...formState,
                        [e.target.name]: e.target.value,
                      })
                    }
                    size='small'
                    name='message'
                    id='message-area'
                    label='Текст уведомления'
                    multiline
                    rows={12}
                    value={formState?.message || ''}
                  />
                </FormControl>
              </div>
            </div>

            <div className={styles.footer}>
              <Button
                appearance='outlined'
                // onClick={handleClose}
                onClick={() => {
                  setFormState({
                    channelId: null,
                    complex: [],
                    title: null,
                    message: '',
                  })
                  handleClose()
                }}
              >
                Отменить изменения
              </Button>

              <SureSendNotificationModal
                onSubmit={() => {
                  console.log(formState)
                  editChannel({
                    id: formState?.channelId,
                    residential_complex: formState?.complex,
                    message: formState?.message,
                  })
                    .then(() => {
                      refetch()

                      setFormState({
                        channelId: null,
                        complex: [],
                        title: null,
                        message: '',
                      })
                    })
                    .then(() => handleClose())
                }}
                btnTitle={
                  <Button
                    appearance='primary'
                    disabled={formState?.message == '' || formState.channelId == null}
                  >
                    Отправить уведомление
                  </Button>
                }
              />
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  )
}
