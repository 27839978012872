import { apiSlice } from 'redux/api/auth/apiSlice'
import type { IBuilding } from './buildingsApiSlice.props'

export const buildingsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // getBuildings: builder.query<IBuilding[], void>({
    getBuildings: builder.query<any, any>({
      query: ({ query, residential_complex }: any) => {
        return {
          url: 'api/buildings/',
          params: {
            query,
            residential_complex,
          },
        }
      },
    }),
    getBuildingsById: builder.query<any, any>({
      query: ({ id }: any) => {
        return {
          url: `api/buildings/${id}`,
        }
      },
    }),
    createBuilding: builder.mutation({
      query: ({
        street,
        number,
        building,
        block,
        entrance,
        floors,
        residential_complex,
        billing_companies,
      }: any) => ({
        url: 'api/buildings/',
        method: 'POST',
        body: {
          street,
          number,
          building,
          block,
          entrance,
          floors,
          residential_complex,
          billing_companies,
        },
      }),
    }),
    removeBuilding: builder.mutation({
      query: (id: any) => ({
        url: `api/buildings/${id}`,
        method: 'DELETE',
        // keepUnusedDataFor: 0,
      }),
    }),
    editBuilding: builder.mutation({
      query: ({
        id,
        street,
        number,
        building,
        block,
        entrance,
        floors,
        residential_complex,
        billing_companies,
      }: any) => ({
        url: `api/buildings/${id}/`,
        method: 'PUT',
        body: {
          street,
          number,
          building,
          block,
          entrance,
          floors,
          residential_complex,
          billing_companies,
        },
      }),
    }),
  }),
})

export const {
  useGetBuildingsQuery,
  useCreateBuildingMutation,
  useGetBuildingsByIdQuery,
  useEditBuildingMutation,
  useRemoveBuildingMutation,
} = buildingsApiSlice
