import { AddTenantOption } from 'pages/OrdersPage/CreateOrderModal/constants'
import { apiSlice } from 'redux/api/auth/apiSlice'

export const appealsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAppealIssueType: builder.query<any, any>({
      query: () => {
        return {
          url: 'api/appeal-issue-types/',
          // params: {
          //   query,
          // },
        }
      },
    }),
    getAppeals: builder.query<any, any>({
      query: ({ query }: any) => {
        return {
          url: 'api/appeals/',
          params: {
            query,
          },
        }
      },
    }),
    getAppealsById: builder.query<any, any>({
      query: ({ id }: any) => {
        return {
          url: `api/appeals/${id}`,
        }
      },
    }),
    createAppeal: builder.mutation({
      query: (props: any) => {
        const {
          addTenantOption,
          title,
          description,
          priority,
          status,
          uploaded_files,
          uploaded_images,
          target,
          initiated_by,
          appealIssueType,
          appealIssueType_id,
          typeVal,
          deadline,
          responsible,
        } = props

        console.log('#LOG_PROPS_TO_SEND', props)

        const formdata = new FormData()
        formdata.append('priority', priority)
        formdata.append('status', status)
        formdata.append('title', title)
        formdata.append('description', description)
        formdata.append('target.type', target.type)
        if (target.flat) formdata.append('target.flat', target.flat)

        if (target.residential_complex)
          formdata.append('target.residential_complex', target.residential_complex)
        if (target.building) formdata.append('target.building', target.building)

        formdata.append('responsible', responsible)

        formdata.append('issue_type', appealIssueType_id)

        formdata.append('type', typeVal)
        formdata.append('deadline', deadline)

        if (addTenantOption === AddTenantOption.FROM_DB) {
          formdata.append('initiated_by.tenant', initiated_by.tenant)
        }

        if (addTenantOption === AddTenantOption.ADD_NEW_USER) {
          formdata.append('initiated_by.phone', initiated_by.phone)
          formdata.append('initiated_by.fullname', initiated_by.fullname)
        }

        uploaded_images.forEach((file: any, index: any) => {
          formdata.append(`uploaded_images[${index}]`, file, file.name)
        })

        uploaded_files.forEach((file: any, index: any) => {
          formdata.append(`uploaded_files[${index}]`, file, file.name)
        })

        return {
          url: 'api/appeals/',
          method: 'POST',
          body: formdata,
        }
      },
    }),

    editAppeal: builder.mutation({
      query: (props: any) => {
        const {
          id,
          title,
          description,
          priority,
          status,
          issue,
          type,
          deadline,
          typeVal,
          target,
          phone,
          fullname,
          tenant,
          residential_complex,
          building,
          flat,
          uploaded_images,
          uploaded_files,
          addTenantOption,
          appealIssueType_id,
        } = props

        const formdata = new FormData()

        console.log('#LOG_TEST_IMG_te', props)

        formdata.append('title', title)
        formdata.append('status', status)
        formdata.append('description', description)
        formdata.append('priority', priority)
        formdata.append('type', typeVal)
        formdata.append('deadline', deadline)

        formdata.append('issue_type', appealIssueType_id)

        formdata.append('target.type', target.type)
        formdata.append('target.residential_complex', residential_complex)
        formdata.append('target.building', building)

        if (flat) formdata.append('target.flat', flat)

        // formdata.append('issue', issue)
        // formdata.append('images', '')

        if (addTenantOption === AddTenantOption.FROM_DB) {
          formdata.append('initiated_by.tenant', tenant)
        }

        if (addTenantOption === AddTenantOption.ADD_NEW_USER) {
          formdata.append('initiated_by.phone', phone)
          formdata.append('initiated_by.fullname', fullname)
        }

        uploaded_images.forEach((file: any, index: any) => {
          formdata.append(`uploaded_images[${index}]`, file, file.name)
        })

        uploaded_files.forEach((file: any, index: any) => {
          formdata.append(`uploaded_files[${index}]`, file, file.name)
        })

        return {
          url: `api/appeals/${id}/`,
          method: 'PUT',
          body: formdata,
          // body: {
          //   title,
          //   status,
          //   description,
          //   priority,
          //   issue,
          //   target: {
          //     type,
          //     residential_complex,
          //     building,
          //     flat,
          //   },
          //   initiated_by: {
          //     phone,
          //     fullname,
          //     tenant,
          //   },
          // },
        }
      },
    }),
    removeBuilding: builder.mutation({
      query: (id: any) => ({
        url: `api/appeals/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
})

export const {
  useGetAppealIssueTypeQuery,
  useGetAppealsQuery,
  useEditAppealMutation,
  useCreateAppealMutation,
  useGetAppealsByIdQuery,
} = appealsApiSlice
