import { lazy } from 'react'
import { IRoute } from './types'
import ChannelsPage from 'pages/ChannelsPage/ChannelsPage'
import ChannelsByIdPage from 'pages/ChannelsByIdPage/ChannelsByIdPage'
import ChatPage from 'pages/ChatPage/ChatPage'

const LoginPage = lazy(() => import('pages/LoginPage/LoginPage'))
const LogoutPage = lazy(() => import('pages/LogoutPage/LogoutPage'))
const HouseApartmentsPage = lazy(() => import('pages/HouseApartmentsPage/HouseApartmentsPage'))
const AgentIdApartmentPage = lazy(() => import('pages/AgentIdApartmentPage/AgentIdApartmentPage'))
const OrdersPage = lazy(() => import('pages/OrdersPage/OrdersPage'))

const ComplexListPage = lazy(() => import('pages/ComplexListPage/ComplexListPage'))
const ComplexIdPage = lazy(() => import('pages/ComplexIdPage/ComplexIdPage'))
const ApartmentIdPage = lazy(() => import('pages/ApartmentsIdPage/ApartmentsIdPage'))
const TenantPage = lazy(() => import('pages/TenantPage/TenantPage'))
const PersonPage = lazy(() => import('pages/PersonPage/PersonPage'))
const IpusPage = lazy(() => import('pages/IpusPage/IpusPage'))
const IpuIdPage = lazy(() => import('pages/IpuIdPage/IpuIdPage'))
const AgentsPage = lazy(() => import('pages/AgentsPage/AgentsPage'))
const AgentIdPage = lazy(() => import('pages/AgentIdPage/AgentIdPage'))
const PeoplePage = lazy(() => import('pages/PeoplePage/PeoplePage'))
const SupportPage = lazy(() => import('pages/SupportPage/SupportPage'))
const NewsPage = lazy(() => import('pages/NewsPage/NewsPage'))
const IndexPage = lazy(() => import('pages/IndexPage/IndexPage'))
const DashboardPage = lazy(() => import('pages/Dashboard/Dashboard'))

export const publicRoutes: IRoute[] = [
  {
    path: 'login',
    element: <LoginPage />,
  },
  {
    path: 'logout',
    element: <LogoutPage />,
  },
]

export const protectedRoutes: IRoute[] = [
  {
    path: '/',
    element: <IndexPage />,
  },
  {
    path: 'objects/complexes',
    element: <ComplexListPage />,
  },
  {
    path: 'objects/complexes/:complexId',
    element: <ComplexIdPage />,
  },
  {
    path: 'objects/complexes/:complexId/houses/:houseId',
    element: <HouseApartmentsPage />,
  },
  {
    path: 'objects/complexes/:complexId/houses/:houseId/:apartmentId',
    element: <ApartmentIdPage />,
  },
  {
    path: 'ipus',
    element: <IpusPage />,
  },
  {
    path: 'ipus',
    element: <IpusPage />,
  },
  {
    path: 'ipus/:ipusId',
    element: <IpuIdPage />,
  },
  {
    path: 'people',
    element: <PeoplePage />,
  },
  {
    path: 'channels',
    element: <ChannelsPage />,
    // element: <PeoplePage />,
  },
  {
    path: 'channels/:channelId',
    element: <ChannelsByIdPage />,
    // element: <PeoplePage />,
  },
  {
    path: 'orders',
    element: <OrdersPage />,
  },
  {
    path: 'bills',
    element: <AgentsPage />,
  },
  {
    path: 'bills/:agentId',
    element: <AgentIdPage />,
  },
  {
    path: 'bills/:agentId/:apartment/:biilId',
    element: <AgentIdApartmentPage />,
  },
  {
    path: 'news',
    element: <NewsPage />,
  },
  {
    path: 'support',
    element: <SupportPage />,
  },
  {
    path: 'tenants/:idTenant',
    element: <TenantPage />,
  },
  {
    path: 'people/:idTenant',
    element: <PersonPage />,
  },

  {
    path: 'dashboard',
    element: <DashboardPage />,
  },
  {
    path: 'chat',
    element: <ChatPage />,
  },
]
