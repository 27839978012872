import { apiSlice } from 'redux/api/auth/apiSlice'
// import type { IBuilding } from './complexesApiSlice.props'ß

export const complexes = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // getBuildings: builder.query<IBuilding[], string>({
    getComplexes: builder.query<any, any>({
      query: ({ query }: any) => {
        return {
          url: 'api/residential_complexes/',
          params: {
            query,
          },
        }
      },
      keepUnusedDataFor: 0,
    }),

    getComplexBuildingsById: builder.query({
      query: (id) => `api/residential_complexes/${id}`,
      keepUnusedDataFor: 0,
    }),
  }),
})

export const { useGetComplexesQuery, useGetComplexBuildingsByIdQuery } = complexes
