import styled from 'styled-components'

export const Card = styled.div`
  width: fit-content;
  padding: 24px;
  background: #ffffff;
  border: 1px solid #f1f2f4;
  border-radius: 16px;
  transition: all ease-in-out 0.3s;
  &:hover {
    box-shadow: 0 0 8px #f6f7f8;
  }
`
