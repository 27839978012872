import { API_TAGS, apiSlice } from 'redux/api/auth/apiSlice'

export const chatsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addAttachment: builder.mutation({
      query: ({ attachmentFile }: any) => {
        const attachment = new FormData()
        attachment.append('attachment', attachmentFile)

        // let count = attachmentFile
        // do {
        //   text += i + '<br>'
        //   i++
        // } while (i < 5)

        // attachmentFile.forEach((element: any) => {
        //   attachment.append('attachment', element)
        // })

        // console.log('fileTest_8880', attachmentFile)
        // attachment.append('attachment', JSON.stringify(attachmentFile))
        // attachment.append('attachment', attachmentFile[0])
        // attachment.append('attachment', attachmentFile[1])

        return {
          url: 'chat/api/v1/attachments/',
          method: 'POST',
          body: attachment,

          // headers: {
          //   'Content-Type': 'undefined',
          //   // 'Content-Type': 'multipart/form-data;',
          // },
          // formData: true,
        }
      },
      // invalidatesTags: [API_TAGS.CHAT_LIST_GET],
    }),
  }),
})

export const { useAddAttachmentMutation } = chatsApiSlice
