import { createSlice } from '@reduxjs/toolkit'
import type { IState } from './auth.props'

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: null,
    token: null,
    refresh: null,
  },
  reducers: {
    setCredentials: (state, action) => {
      const { access, email, refresh } = action.payload
      localStorage.setItem('token', access)
      state.user = email
      state.token = access
      state.refresh = refresh
    },
    logOut: (state) => {
      localStorage.removeItem('token')
      state.user = null
      state.token = null
      state.refresh = null
      window.location.href = '/login'
    },
  },
})

export const { setCredentials, logOut } = authSlice.actions
export default authSlice.reducer

// Selectors
export const selectCurrentUser = (state: IState) => state.auth.user
export const selectCurrentToken = (state: IState) => state.auth.token
