import { useState } from 'react'
import styles from './Carousel.module.css'
import { CarouselProps } from './Carousel.props'
import { Button } from 'components/Button/Button'
import { ReactComponent as RightIcon } from './img/arrow_right.svg'
import { ReactComponent as LeftIcon } from './img/arrow_left.svg'

export const Carousel = ({ images }: CarouselProps): JSX.Element => {
  const [index, setIndex] = useState(0)
  return (
    <div className={styles.main_container}>
      <Button
        appearance='container'
        className={styles.btn_left}
        onClick={() => {
          if (index < images.length - 1) {
            setIndex(index + 1)
          } else {
            setIndex(0)
          }
        }}
      >
        <LeftIcon />
      </Button>

      <Button
        appearance='container'
        className={styles.btn_right}
        onClick={() => {
          if (index >= images.length - 1) {
            setIndex(0)
          } else {
            setIndex(index + 1)
          }
        }}
      >
        <RightIcon />
      </Button>

      <div className={styles.slider_container}>
        {<img src={images[index]} className={styles.slide} />}
      </div>
    </div>
  )
}
