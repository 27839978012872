import TextField from '@mui/material/TextField'
import styled from 'styled-components'
import type { IInput } from './Input.props'

const CustomTextField = styled(TextField)`
  /* background: red; */
  display: grid;

  & .MuiInputBase-input {
    /* background: red; */
  }

  .Mui-focused .MuiOutlinedInput-notchedOutline {
    /* border-color: #d3d4d3 !important; */
    border-color: #4060ff !important; // #EDF1F6
  }
`

// export const Input = ({ id = 'outlined-basic', placeholder }: IInput) => (
//     <CustomTextField id={id} placeholder={placeholder} />
//     )
export const Input = ({
  disable,
  id = 'outlined-basic',
  label,
  variant,
  placeholder,
  name,
  value,
  onChange,
  // onClick,
  size,
  error,
  ref,
  type,
  ...props
}: IInput) => {
  return (
    <CustomTextField
      // onClick={onClick}
      type={type}
      disabled={disable}
      ref={ref}
      error={error}
      onChange={onChange}
      name={name}
      id={id}
      label={label}
      placeholder={placeholder || label}
      variant={variant}
      value={value}
      // variant={variant}
      size={size || 'medium'}
      {...props}
    />
  )
}
