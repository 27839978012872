import styles from './MsgContainer.module.css'
import cn from 'classnames'
import moment from 'moment'
import { Button, Dropdown, Title } from 'components'
import React, { useEffect, useRef, useState } from 'react'
import { ReactComponent as SearchIcon } from 'assets/img/search.svg'
import { ReactComponent as SendArrow } from './img/arrow_send.svg'
import { ReactComponent as CrossSvg } from 'assets/img/cross.svg'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Modal } from '@mui/material'
import { ChatStatus, useUpdChatMutation } from 'redux/features/chats/chatsApiSlice'
import { updResponsibleForChat } from 'redux/features/chats/chatsApiReducer'
import { SendFileClip } from './SendFileClip/SendFileClip'
import { ReactComponent as ClipSvg } from 'assets/img/clip_send_file.svg'
// import { ReactComponent as DotsSvg } from 'pages/ApartmentsIdPage/AddTenantModal/img/dots_v.svg'
// import { ModalWindow } from 'components/Modal/Modal'
import { useInView } from 'react-intersection-observer'
import { PAGE_SIZE_MESSAGES_IN_CHAT } from 'pages/ChatPage/ChatPage'
import { FinishChatModal } from 'pages/ChatPage/Chat/FinishChat/FinishChatModal'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 460,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  padding: '24px',
  borderRadius: '24px',
  border: 'none',
}
interface ChatMessage {
  text: string
  date: string
  id: number
  author: number
  time?: any
}

interface ChatGroup {
  date: string
  messages: ChatMessage[]
}

interface Props {
  chat: any[]
}

function getUniqueById(array: any) {
  const uniqueMap = new Map()

  array.forEach((item: any) => {
    if (!uniqueMap.has(item.id)) {
      uniqueMap.set(item.id, item)
    }
  })

  return Array.from(uniqueMap.values())
}

export const MsgContainer = ({
  currentChat,
  currentChatName,
  chat,
  sendMessage,
  getCurrentChatMessages,
}: any) => {
  const myId = useSelector((state: any) => state?.profileData?.id)
  const chatRefFlatData = useSelector((state: any) => state?.chat?.chatRefFlatData)
  const isMsgListReset = useSelector((state: any) => state?.chat?.isMsgListReset)
  const countMsg = useSelector((state: any) => state?.chat?.countMsg)

  // const chatReducer = useSelector((state: any) => state?.chat)

  const [lastMsgIdForPagination, setLastMsgIdForPagination] = useState(null)
  const [page, setPage] = useState(1)

  const groups = React.useMemo(() => {
    const groupsMap = new Map<string, any>()

    setLastMsgIdForPagination(chat[0]?.id)
    console.log('chat*****', chat)

    // getUniqueById is used to avoid duplicated values
    // chat.forEach((message: any) => {
    getUniqueById(chat).forEach((message: any) => {
      const dateStr = moment(message.sent_at).format('DD MMMM YYYY') // formattedDate // date.toISOString().substr(0, 10)
      // console.log('ENTRY___groups^^^', dateStr)

      const group = groupsMap.get(dateStr) ?? {
        date: moment(message.sent_at).format('DD MMMM'),
        messages: [],
      }

      const utcTime = moment.utc(message.sent_at, 'YYYY-MM-DD HH:mm:ss')
      // Convert the UTC time to the local time zone
      const localTime = utcTime.local()

      group.messages.push({ ...message, time: moment(localTime).format('HH:mm') })
      groupsMap.set(dateStr, group)
    })

    // console.log('IDENTIFIER_', chat.length)
    // console.log('IDENTIFIER_', groupsMap)

    const groupedArrMsg = Array.from(groupsMap.values())
    // console.log('ENTRY___groups^^^', groupsMap)

    const monthMap: { [key: string]: number } = {
      января: 0,
      февраля: 1,
      марта: 2,
      апреля: 3,
      мая: 4,
      июня: 5,
      июля: 6,
      августа: 7,
      сентября: 8,
      октября: 9,
      ноября: 10,
      декабря: 11,
    }

    const parseDateToTimestamp = (dateStr: string): number => {
      // console.log('ENTRY___groups^^^', day, month, monthIndex, groupedArrMsg)
      const [day, month] = dateStr.split(' ')
      const monthIndex = monthMap[month]
      const date = new Date(2024, monthIndex, parseInt(day, 10))
      return date.getTime()
    }
    const parseSentAtToTimestamp = (sentAt: string): number => {
      return new Date(sentAt).getTime()
    }
    const formatDate = (date: Date): string => {
      const day = date.getDate()
      const month = Object.keys(monthMap).find((key) => monthMap[key] === date.getMonth())
      return `${day} ${month}`
    }

    groupedArrMsg.sort((a, b) => parseDateToTimestamp(a.date) - parseDateToTimestamp(b.date))

    // Sort messages within each object by sent_at
    groupedArrMsg.forEach((item) => {
      item.messages.sort(
        (a: any, b: any) => parseSentAtToTimestamp(a.sent_at) - parseSentAtToTimestamp(b.sent_at),
      )
    })

    // Convert back to the desired format
    groupedArrMsg.forEach((item) => {
      const date = new Date(parseDateToTimestamp(item.date))
      item.date = formatDate(date)
    })

    const groupedArrMsgSorted = groupedArrMsg
    return groupedArrMsgSorted
  }, [chat])

  const [searchText, setSearchText] = useState('')
  const msgTxt = useRef<any>()
  const [msgTxtCount, setMsgTxtCount] = useState('')
  const [isSearch, setIsSearch] = useState<boolean>(false)

  useEffect(() => {
    if (msgTxt.current) {
      msgTxt.current.value = ''
    }
  }, [chatRefFlatData?.building])

  const onSubmitMsg = (e: any, attachments: any) => {
    if (attachments) {
      sendMessage(msgTxt.current.value, attachments)
    } else {
      e.preventDefault()
      if (msgTxt?.current?.value?.trim() === '') return null
      sendMessage(msgTxt.current.value, null)
      msgTxt.current.value = ''
      // setIsOpenController(false)
      setOpen(false)
    }
  }

  const innerRef = useRef<any>(null)

  const [isFirstRenderForScroll, setIsFirstRenderForScroll] = useState(true)
  const [prevChat, setPrevChat] = useState(null)

  // useEffect(() => {
  //   if (!prevChat) {
  //     setPrevChat(currentChat)
  //     console.log('currentChat/**/*/', prevChat, currentChat)
  //   }

  //   if (prevChat !== currentChat) {
  //     setPrevChat(currentChat)
  //     setIsFirstRenderForScroll(true)
  //   }

  //   if (isFirstRenderForScroll) {
  //     innerRef?.current?.scrollTo({
  //       top: innerRef?.current?.scrollHeight,
  //       behavior: 'smooth',
  //     })
  //   }
  //   setTimeout(() => {
  //     setIsFirstRenderForScroll(false)
  //   }, 3000)
  // }, [currentChat])

  useEffect(() => {
    if (!prevChat) {
      setPrevChat(currentChat)
    }

    if (prevChat !== currentChat) {
      setPage(1)
      setPrevChat(currentChat)
      setIsFirstRenderForScroll(true)
    }

    if (page == 1) innerRef?.current?.scrollTo(0, innerRef?.current?.scrollHeight)

    // #START Ниже рабочи вариант scrollTO
    // if (isFirstRenderForScroll) {
    //   // innerRef?.current?.scrollTo({
    //   //   top: innerRef?.current?.scrollHeight,
    //   //   behavior: 'smooth',
    //   // })
    //   innerRef?.current?.scrollTo(0, innerRef?.current?.scrollHeight)
    // }
    // setTimeout(() => {
    //   setIsFirstRenderForScroll(false)
    // }, 3000)
    // #END - Ниже рабочи вариант scrollTO
  }, [chat, groups])

  const handleSearchTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value)
  }

  // const [isOpenController, setIsOpenController] = useState(false)

  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const openImage = (url: string) => {
    console.log(url)
  }
  const [updChat] = useUpdChatMutation()
  const dispatch = useDispatch()

  const { ref, inView, entry } = useInView({
    /* Optional options */
    // threshold: 0,
    // root,
    rootMargin: '100px',
    threshold: 0.01,
  })

  // useEffect(() => {
  //   // console.log('root_trackVisibility', root)
  // }, [])

  // useEffect(() => {
  //   isMsgListReset == true && setPage(1)
  // }, [isMsgListReset])

  const myyRef = useRef<any>()
  const [myElementIsVisible, setMyElementIsVisible] = useState<boolean>()

  useEffect(() => {
    const pageSize = PAGE_SIZE_MESSAGES_IN_CHAT
    const count = countMsg
    const totalPages = Math.ceil(count / pageSize)

    if (isMsgListReset == true) {
      setPage(1)
    } else {
      if (page < totalPages) setPage(page + 1)
    }

    // const observer = new IntersectionObserver(
    //   (entries) => {
    //     const entry = entries[0]
    //     setMyElementIsVisible(entry.isIntersecting)
    //   },
    //   { threshold: -0.5 },
    // )
    // observer.observe(myyRef.current)
  }, [myElementIsVisible])

  useEffect(() => {
    // ref.current = null
    const pageSize = PAGE_SIZE_MESSAGES_IN_CHAT
    const count = countMsg
    const totalPages = Math.ceil(count / pageSize)

    if (isMsgListReset == true) {
      setPage(1)
    } else {
      if (page < totalPages) setPage(page + 1)
    }

    // getCurrentChatMessages()
  }, [inView, entry, isMsgListReset])

  useEffect(() => {
    // setPage((prev) => ++prev)
    // if(count > result.length) {
    const pageSize = PAGE_SIZE_MESSAGES_IN_CHAT
    const count = countMsg

    const totalPages = Math.ceil(count / pageSize)
    const currentPage = page

    const isLastPage = currentPage > totalPages

    // console.log(`#inView_INVIEW:${inView}, COUNT: ${count}, TOTAL: ${totalPages}, PAGE: ${page} `)
    if (!isLastPage) {
      getCurrentChatMessages(page)
    }
  }, [page])

  return (
    <div className={styles.msg_container}>
      {isSearch ? (
        <div
          className={cn(styles.header_msg_row, {
            [styles.header_search_msg_row]: isSearch,
          })}
        >
          <div className={styles.header_search_input}>
            <SearchIcon />

            <input
              name='search'
              placeholder='Поиск'
              value={searchText}
              onChange={handleSearchTextChange}
            />
          </div>

          <Button
            className={styles.send_arrow}
            appearance='container'
            disabled={false}
            onClick={() => setIsSearch(!isSearch)}
          >
            <CrossSvg />
          </Button>

          {/* <Dropdown
            btnTitle={
              <div className={styles.dropbtn_title}>
                <DotsSvg />
              </div>
            }
          >
            <div className={styles.dropdown_container}>
              <Button
                appearance='container'
                onClick={() => {
                  // removeTenantInFlat({ tenant_id: id, flat_id: apartmentId }).then(() => refetch())
                }}
              >
                Перейти к квартире
              </Button>
            </div>
          </Dropdown> */}
        </div>
      ) : (
        <div className={styles.header_msg_row}>
          <div className={styles.header_title}>
            {/* <button onClick={() => console.log(chatRefFlatData?.chatsDataFSM?.title)}>ll</button> */}
            {chatRefFlatData?.chatsDataFSM?.title}
            {/* {chatRefFlatData?.building &&
              `${chatRefFlatData?.building?.address} кв ${chatRefFlatData.number}`} */}
          </div>
          <div
            style={{
              opacity: chatRefFlatData?.chatsDataFSM?.responsible == myId ? 1 : 0,
              boxSizing: 'border-box',
            }}
          >
            <FinishChatModal
              // refetch={refetch}
              onFinishHandler={() => {
                const updChatData = {
                  id: chatRefFlatData?.chatsDataFSM?.id,
                  chat_id: chatRefFlatData?.chat_ref,
                  title: currentChatName,
                  responsible: null,
                  status: ChatStatus.CLOSED,
                }
                updChat({ ...updChatData }).then((res: any) => {
                  dispatch(
                    updResponsibleForChat({
                      ...updChatData,
                      responsible: null,
                    }),
                  )
                })
                // .catch(() => {
                //   setIsError(true)
                // })
              }}
              btnTitle={
                // <Button appearance='grey' className={styles.btn_add_action}>
                //   Добавить человека
                // </Button>

                <Button className={styles.cancel_chat_btn} appearance='primary' disabled={false}>
                  Завершить чат
                </Button>
              }
            />

            {/* 
            <Button
              className={styles.send_arrow}
              appearance='container'
              disabled={false}
              onClick={() => setIsSearch(!isSearch)}
            >
              <SearchIcon />
            </Button>

            <Dropdown
              btnTitle={
                <div className={styles.dropbtn_title}>
                  <DotsSvg />
                </div>
              }
            >
              <div className={styles.dropdown_container}>
                <Button appearance='container'>Перейти к квартире</Button>
              </div>
            </Dropdown> */}
          </div>
        </div>
      )}

      <div
        ref={innerRef}
        className={cn(styles.msg_list, {
          [styles.msg_list_active]: currentChat,
        })}
        style={{ position: 'relative' }}
      >
        {/* <h1 ref={ref}>
          Page: {page} caht length: {chat.length} count msg: {countMsg}
        </h1> */}

        {/* <h1 ref={ref}>
          Page: {page} caht length: {chat.length} count msg: {countMsg}
        </h1> */}
        <h1
          ref={ref}
          style={{
            position: 'absolute',
            top: '85%',
            border: 'solid red 3px',
            opacity: 0,
          }}
        >
          Page: {page} caht length: {chat.length} count msg: {countMsg}
        </h1>
        {groups.map((group: any) => (
          <div key={group.date}>
            <div className={styles.group_date}>{group.date}</div>
            {group.messages.map((message: any, indx: number) =>
              message?.author?.role != 'tenant' ? (
                <div
                  className={styles.msg_admin_container}
                  key={message?.id}
                  onClick={() => console.log('message', chat)}
                >
                  <div className={styles.msg_admin}>
                    <div className={styles.msg_time_grid}>
                      {message?.author.last_name && (
                        <div className={styles.msg_author_name} style={{ color: 'white' }}>
                          {`${message?.author.last_name} ${message?.author.first_name} ${message?.author.middle_name}`}
                        </div>
                      )}
                      {/* 
                      {message?.attachments.length > 0 && (
                        <button onClick={() => console.log('ATTACH', message?.attachments[0])}>
                          ttt
                        </button>
                        // <ImageFullScereen url={message?.attachments[0]?.url} />
                      )} */}
                      {message?.attachments.length > 0 &&
                        (message?.attachments[0]?.extension == 'jpg' ||
                        message?.attachments[0]?.extension == 'png' ? (
                          <ImageFullScereen url={message?.attachments[0]?.url} />
                        ) : (
                          <a target='_blank' rel='noreferrer' href={message?.attachments[0]?.url}>
                            Файл
                          </a>
                        ))}
                      <div className={styles.msg_txt}>
                        <div className={styles.msg_user_txt}>
                          {message?.text}
                          {/* {lastMsgIdForPagination == message?.id && <></>} */}
                        </div>
                      </div>
                      <div className={styles.msg_time_manager}>
                        {moment(message?.sent_at).format('HH:mm')}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  className={styles.msg_user}
                  key={message.id}
                  onClick={() => console.log('message', chat)}
                >
                  <div className={styles.msg_time_grid}>
                    <div
                      className={styles.msg_author_name}
                    >{`${message.author.last_name} ${message.author.first_name} ${message.author.middle_name}`}</div>
                    {/* {message?.attachments.length > 0 && (
                      <ImageFullScereen url={message?.attachments[0]?.url} />
                    )} */}
                    {message?.attachments.length > 0 &&
                      (message?.attachments[0]?.extension == 'jpg' ||
                      message?.attachments[0]?.extension == 'png' ? (
                        <ImageFullScereen url={message?.attachments[0]?.url} />
                      ) : (
                        <a target='_blank' rel='noreferrer' href={message?.attachments[0]?.url}>
                          Файл
                        </a>
                      ))}
                    <div className={styles.msg_txt}>{message.text}</div>
                    <>
                      {/* <h2>{`Header inside viewport ${inView}.${lastMsgIdForPagination}`}</h2> */}

                      {/* <h1 ref={ref}>
                        Page: {page} caht length: {chat.length} count msg: {countMsg}
                      </h1> */}
                    </>
                    <div className={styles.msg_time}>{moment(message.sent_at).format('HH:mm')}</div>
                    {/* <div className={styles.msg_time}>{moment(message.sent_at).format('HH:mm')}</div> */}
                  </div>
                </div>
              ),
            )}
          </div>
        ))}
      </div>
      {/* <h1 style={{ color: 'red' }}>{chatRefFlatData?.chatsDataFSM?.responsible}</h1> */}
      {chatRefFlatData?.isOpen && (
        <>
          {/* {chatRefFlatData?.chatsDataFSM?.responsible != myId && ( */}
          {chatRefFlatData?.chatsDataFSM?.responsible == null && (
            <div className={styles.take_chat_wrapper}>
              <TakeChatInProgress
                updChatData={{
                  id: chatRefFlatData?.chatsDataFSM?.id,
                  chat_id: chatRefFlatData?.chat_ref,
                  title: currentChatName,
                  responsible: myId,
                  status: ChatStatus.OPEN,
                }}
              />
            </div>
          )}

          {chatRefFlatData?.chatsDataFSM?.responsible !== myId &&
            chatRefFlatData?.chatsDataFSM?.responsible !== null && (
              <div className={styles.take_chat_wrapper}>
                <ChatAlreadyTaken />
              </div>
            )}
        </>
      )}

      {!chatRefFlatData?.isOpen && (
        <div className={styles.send_msg_row} style={{ height: '96px' }}></div>
      )}

      {/* {chatRefFlatData?.isOpen && chatRefFlatData?.chatsDataFSM?.responsible === myId && ( */}
      {chatRefFlatData?.isOpen && chatRefFlatData?.chatsDataFSM?.responsible === myId && (
        <SendMsgContainer
          msgTxt={msgTxt}
          setMsgTxtCount={setMsgTxtCount}
          currentChat={currentChat}
          setOpen={setOpen}
          msgTxtCount={msgTxtCount}
          handleOpen={handleOpen}
          onSubmitMsg={onSubmitMsg}
          sendMessageEvent={sendMessage}
          open={open}
          handleClose={handleClose}
        />
      )}

      {/* <SendMsgContainer
        msgTxt={msgTxt}
        setMsgTxtCount={setMsgTxtCount}
        currentChat={currentChat}
        setOpen={setOpen}
        msgTxtCount={msgTxtCount}
        handleOpen={handleOpen}
        onSubmitMsg={onSubmitMsg}
        sendMessageEvent={sendMessage}
        open={open}
        handleClose={handleClose}
      /> */}
    </div>
  )
}

const SendMsgContainer = ({
  msgTxt,
  setMsgTxtCount,
  currentChat,
  setOpen,
  msgTxtCount,
  handleOpen,
  open,
  handleClose,
  sendMessageEvent,
  onSubmitMsg,
}: any) => {
  return (
    <div className={styles.send_msg_row}>
      <SendFileClip
        sendMessageEvent={sendMessageEvent}
        isDisabled={false}
        btnTitle={
          <Button
            appearance='container'
            className={styles.btn_order}
            onClick={() => {
              console.log('LOGGGG:')
            }}
          >
            <ClipSvg />
          </Button>
        }
      />

      <textarea
        id='msg'
        name='msg'
        rows={3}
        ref={msgTxt}
        onChange={(e) => {
          setMsgTxtCount(e.target.value)
        }}
        placeholder={currentChat ? 'Напишите сообщение' : ''}
        onKeyDown={(e) => {
          if ((e.key === 'Enter' || e.keyCode === 13) && e.shiftKey) return null

          if (e.key === 'Enter' || e.keyCode === 13) {
            e.preventDefault()
            // onSubmitMsg(e)
            if (msgTxt?.current?.value?.trim() === '') return null
            setOpen(true)
            return null
          }
        }}
        disabled={currentChat ? false : true}
      />
      {msgTxtCount.length > 0 && (
        <>
          <div onClick={handleOpen}>
            <Button
              className={styles.send_arrow}
              appearance='container'
              disabled={false}
              onClick={() => setOpen(true)}
            >
              <SendArrow />
            </Button>
          </div>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'
          >
            <Box sx={style}>
              <div className={styles.send_msg_modal_container}>
                <Title size='20px' margin={'0 0 32px 0'}>
                  Отправить это сообщение?
                </Title>
                <div className={styles.send_msg_actions_container}>
                  <Button
                    // className={styles.send_arrow}
                    appearance='primary-light'
                    disabled={false}
                    onClick={onSubmitMsg}
                  >
                    Да
                  </Button>
                  <Button
                    appearance='primary'
                    disabled={false}
                    onClick={() => {
                      setOpen(!open)
                    }}
                  >
                    Отмена
                  </Button>
                </div>
              </div>
            </Box>
          </Modal>
        </>
      )}
    </div>
  )
}

const TakeChatInProgress = ({ updChatData }: any) => {
  const [updChat] = useUpdChatMutation()
  const dispatch = useDispatch()

  const [isError, setIsError] = useState<boolean>(false)
  const handleClose = () => {
    setIsError(false)
  }

  return (
    <>
      {isError && (
        <Modal
          open={isError}
          onClose={handleClose}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
        >
          <Box sx={style}>
            <div className={styles.smth_wrong_title}>
              <Title size='24px'>Не получилось взять чат в работу</Title>
            </div>
            <div className={styles.smth_wrong_descr}>
              Что-то пошло не так, пожалуйста, обновите страницу и попробуйте еще раз
            </div>
            <div className={styles.col_2}>
              <Button appearance='primary' onClick={handleClose} className={styles.smth_wrong_btn}>
                Понятно
              </Button>
            </div>
          </Box>
        </Modal>
      )}
      <div className={styles.take_chat_container}>
        <Title className={styles.take_chat_title}>
          Для того, чтобы написать в этот чат, вам нужно нажать на кнопку ниже
        </Title>
        <Button
          className={styles.take_chat_brn}
          appearance='primary'
          disabled={false}
          onClick={() => {
            updChat({ ...updChatData })
              .then((res: any) => {
                if (res?.data?.responsible) {
                  dispatch(
                    updResponsibleForChat({
                      ...updChatData,
                      responsible: res?.data?.responsible,
                      is_active: true,
                      status: ChatStatus.OPEN,
                      reference: res?.data?.reference,
                    }),
                  )
                }
              })

              .catch(() => {
                setIsError(true)
              })
          }}
        >
          Взять чат в работу
        </Button>
      </div>
    </>
  )
}

const ChatAlreadyTaken = () => {
  return (
    <div className={styles.take_chat_container}>
      <Title className={styles.take_chat_title}>Чат уже взят в работу другим менеджером</Title>
    </div>
  )
}

export const ImageFullScereen = ({ url }: any) => {
  const [isOpened, setIsOpened] = useState(false)
  return (
    <>
      {isOpened && (
        <div className={styles.img_full_container}>
          <img src={url} alt='' />

          <Button
            appearance='container'
            disabled={false}
            onClick={() => setIsOpened(!isOpened)}
            className={styles.cross}
          >
            <CrossSvg />
          </Button>
        </div>
      )}
      <img
        className={styles.img_to_open}
        src={url}
        alt=''
        // width={200}
        // height={200}
        onClick={() => setIsOpened(!isOpened)}
      />
    </>
  )
  return <div>{url}</div>
}
