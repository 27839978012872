import TextField from '@mui/material/TextField'
import styled from 'styled-components'
import type { ITextArea } from './TextArea.props'

const CustomTextField = styled(TextField)`
  display: grid;

  & .MuiInputBase-input {
    /* background: red; */
  }

  .Mui-focused .MuiOutlinedInput-notchedOutline {
    /* border-color: #d3d4d3 !important; */
    border-color: #4060ff !important; // #EDF1F6
  }
`

export const TextArea = ({
  id = 'outlined-basic',
  size,
  label,
  variant,
  placeholder,
  name,
  onChange,
  multiline = true,
  rows,
  defaultValue,
  value,
  maxLength,
  disabled = false,
  ...props
}: ITextArea) => {
  return (
    <CustomTextField
      disabled={disabled}
      value={value}
      // maxLength={maxLength}
      multiline={multiline}
      rows={rows}
      defaultValue={defaultValue}
      size={size || 'medium'}
      onChange={onChange}
      name={name}
      id={id}
      label={label}
      placeholder={placeholder || label}
      variant={variant}
      {...props}
    />
  )
}
