import { apiSlice } from 'redux/api/auth/apiSlice'
import { createSlice } from '@reduxjs/toolkit'
import type { IProfile } from './profileApiSlice.props'

export const profile = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getProfile: builder.query<IProfile, void>({
      query: () => 'api/profile/',
      keepUnusedDataFor: 5,
    }),
  }),
})

export const { useGetProfileQuery } = profile

/* Reducers  */
const profileSlice = createSlice({
  name: 'profileData',
  initialState: {
    email: '',
    first_name: '',
    id: '',
    last_name: '',
    middle_name: '',
  },
  reducers: {
    setProfileData: (state, action) => {
      const { email, first_name, id, last_name, middle_name } = action.payload
      state.email = email
      state.first_name = first_name
      state.id = id
      state.last_name = last_name
      state.middle_name = middle_name
    },
    // logOut: (state) => {
    //   localStorage.removeItem('token')
    //   state.user = null
    //   state.token = null
    //   state.refresh = null
    //   window.location.href = '/login'
    // },
  },
})

export const { setProfileData } = profileSlice.actions
export default profileSlice.reducer

// Selectors
export const selectCurrentUserId = (state: any) => state.profileData.id
// export const selectCurrentToken = (state: any) => state.profileData.token
