import { configureStore } from '@reduxjs/toolkit'
import { apiSlice } from './api/auth/apiSlice'
import authReducer from './features/auth/authSlice'
import profileReducer from './features/profile/profileApiSlice'
import chatsReducer from './features/chats/chatsApiReducer'
import sidebarReducer from './features/sidebar/sidebarApiSlice'

export const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    auth: authReducer,
    chat: chatsReducer,
    profileData: profileReducer,
    sidebar: sidebarReducer,
    // chatData: chatsReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(apiSlice.middleware),
  devTools: true,
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
