import styled from 'styled-components'
import type { AvatarProps } from './Avatar.props'

export const Avatar = styled.div<AvatarProps>`
  width: ${({ size }: { size: string }) => size};
  height: ${({ size }: { size: string }) => size};
  border-radius: 100%;
  background-image: url(${({ img }: { img?: string }) => img});
  background-position: center;
  background-size: cover;
`
