import { createTheme } from '@mui/material/styles'
import { orange, red } from '@mui/material/colors'

declare module '@mui/material/styles' {
  interface Theme {
    status: {
      danger: string
    }
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    status?: {
      danger?: string
    }
  }
}

export const theme = createTheme({
  palette: {
    primary: {
      light: '#757ce8',
      main: '#2930FB',
      dark: '#002884',
      contrastText: '#fff',
    },
  },
  // status: {
  //   danger: orange[500],
  // },

  typography: {
    fontFamily: 'Montserrat',
  },
  components: {
    // MuiOutlinedInput: {
    // MuiAutocomplete: {

    // MuiSwitch: {

    //   styleOverrides: {
    //     root: {
    //       // background: 'red',
    //     }
    //   }
    // },
    MuiSwitch: {
      // styleOverrides: {
      //   colorPrimary: red,
      //   checked: {
      //     background: 'red'
      //   }
      // }
    },

    // MuiModal: {
    //   styleOverrides: {
    //     root: {
    //       '&.MuiBox': {
    //         padding: 0,
    //         border: 'solid red',
    //       },
    //     },
    //   },
    // },

    MuiChip: {
      styleOverrides: {
        root: {
          '&.MuiChip-filled': {
            height: '32px',
            backgroundColor: 'rgba(64, 96, 255, 0.05)',
            borderRadius: '8px',
            fontWeight: '400',
            fontSize: '14px',
            lineHeight: '17px',
            color: '#4060FF',
          },

          '&.MuiChip-colorPrimary': {
            color: 'red',
          },

          // '&&': {
          '&& .MuiChip-deleteIcon': {
            color: 'red',
            '& path': { fill: 'rgba(64, 96, 255, 0.1);' },
          },

          // '.MuiChip-deleteIcon': {
          //   // color: '#4060FF',
          //   color: 'transparent',
          // }
        },
      },
    },

    MuiInputBase: {
      styleOverrides: {
        // sizeSmall: {
        //   ':hover': {
        //     borderСolor: 'red',
        //   }
        // },
        // Name of the slot
        root: {
          borderColor: '#D3D4D3',
          '&:hover': {
            borderColor: '#D3D4D3',
          },
          // Some CSS
          borderRadius: '8px',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        // Name of the slot
        root: {
          borderColor: '#D3D4D3',

          '.MuiOutlinedInput-notchedOutline': {
            borderColor: '#D3D4D3',
            borderWidth: '1px',
          },

          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#D3D4D3',
            borderWidth: '1px',
          },

          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            // borderColor: '#D3D4D3',
            borderWidth: '1px',
            borderColor: '#4060ff', // #EDF1F6
          },

          borderRadius: '8px',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          // color: 'red',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        // Name of the slot
        root: {
          borderColor: '#D3D4D3',
          // Some CSS
          // background: 'red',
          // outline: 'red',
        },
      },
    },
  },
})
